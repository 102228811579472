import { ISdk } from 'app/types/environments';
import { CountryTenant } from './infraJson';
import { DefaultTFuncReturn } from 'i18next';

export interface IServiceCard {
  id: string;
  typeCode: ServiceTypesEnum;
  disabled?: boolean;
  disabledTitle?: string | DefaultTFuncReturn;
  title: string | DefaultTFuncReturn;
  desc: string | DefaultTFuncReturn;
  svgSrc: string;
  svgAlt: string;
}

export interface ServiceTypeResponse {
  id: string;
  name: string;
  code: ServiceTypesEnum;
}

export interface ServiceResponse {
  id: string;
  name: string;
  type: ServiceTypeResponse;
  country_code: string;
  created_at: string;
  sdk?: ISdk;
  additional_sdks?: ISdk[];
  increment: string;
  customer_code: CountryTenant;
  has_cert?: boolean;
  oss_enabled?: boolean;
  entry_url?: string;
  aclEndpoint?: string;
  serviceSdk?: ISdk;
  policy_external_id?: string;
  auth_url?: string;
}

export interface CreateBorderServiceResponse {
  data: {
    env_integration: {
      id: string;
    };
    proxy_url: string;
    integration: {
      name: string;
      uuid: string;
    };
  };
}

export enum ServiceTypeFilter {
  selfservice = 'selfservice',
  residentFunctions = 'resident-functions',
  border = 'border',
  encryptionKeys = 'encryption-keys',
  stripe = 'stripe',
  braintree = 'braintree',
  emailGateway = 'email-gateway',
}

export enum ServiceTypesEnum {
  salesforce = 'sf',
  residentFunction = 'rf',
  restapiSse = 'rs',
  border = 'bo',
  encryptionKeys = 'ec',
  paymentVault = 'pv',
  emailGateway = 'eg',
}

export const serviceCodeToFilterMap = {
  [ServiceTypesEnum.salesforce]: ServiceTypeFilter.selfservice,
  [ServiceTypesEnum.restapiSse]: ServiceTypeFilter.selfservice,
  [ServiceTypesEnum.residentFunction]: ServiceTypeFilter.residentFunctions,
  [ServiceTypesEnum.border]: ServiceTypeFilter.border,
  [ServiceTypesEnum.encryptionKeys]: ServiceTypeFilter.encryptionKeys,
  [ServiceTypesEnum.paymentVault]: ServiceTypeFilter.stripe,
  [ServiceTypesEnum.emailGateway]: ServiceTypeFilter.emailGateway,
};

export const serviceCodeToServiceNameMap = {
  [ServiceTypesEnum.restapiSse]: 'restapi',
  [ServiceTypesEnum.salesforce]: 'salesforce',
  [ServiceTypesEnum.residentFunction]: 'residentfunctions',
  [ServiceTypesEnum.border]: 'border',
};
