import React from 'react';
import { MenuItem } from '@mui/material';
import Link from 'next/link';

import { Route } from 'app/utils/constants';

interface IProps {
  href?: Route | string;
  disabled?: boolean;
  isRemote?: boolean;
  close?: () => void;
  onClick?: () => void;
}

const SubmenuItem: React.FC<React.PropsWithChildren<IProps>> = ({
  href,
  disabled = false,
  children,
  isRemote = false,
  onClick,
  close,
  ...rest
}) => {
  if (href instanceof Route) {
    return (
      <MenuItem component={Link} href={href} disabled={disabled} onClick={close} {...rest}>
        {children}
      </MenuItem>
    );
  }

  if (typeof href === 'string') {
    return (
      <MenuItem component='a' href={href} disabled={disabled} target={isRemote ? '_blank' : ''} onClick={close} {...rest}>
        {children}
      </MenuItem>
    );
  }

  return (
    <MenuItem disabled={disabled} onClick={onClick} {...rest}>
      {children}
    </MenuItem>
  );
};

export default SubmenuItem;
