export enum PolicyScopeFilterTypes {
  value = 'value',
  array = 'array',
  notValue = 'notValue',
  notArrayOfValues = 'notArrayOfValues',
  comparison = 'comparison'
}

export enum PolicyScopeComparisonFilterTypes {
  gt = '$gt',
  gte = '$gte',
  lt = '$lt',
  lte = '$lte',
}

export enum PoliciesMaskingType {
  alphanumeric = 'alphanumeric',
  email = 'email',
  numeric = 'numeric',
  formula = 'formula',
}

export enum PoliciesFieldProtectionMode {
  restricted = 'restricted',
  forced = 'forced',
  protected = 'protected',
}

export enum PoliciesMaskingParentStrategyType {
  masking = 'masking',
}
