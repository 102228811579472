import React from 'react';
import { useTranslation } from 'react-i18next';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { alpha, Box, Divider, ListItemText, Tooltip, Typography } from '@mui/material';
import Link from 'next/link';

import api from 'app/api/client';
import { CurrentUser } from 'app/api/models/CurrentUser';
import PortalConfig from 'app/api/models/PortalConfig';
import Logo from 'app/components/common/Logo';
import DropdownMenu from 'app/components/layout/DropdownMenuItem';
import SubmenuItem from 'app/components/layout/SubmenuItem';
import { colors } from 'app/theme-colors';
import { EXTERNAL_LINKS, Route, Routes } from 'app/utils/constants';
import { useUsageContext } from 'app/utils/context/UsageProvider';
import { useRouter } from 'next/router';
import { WhiteALink } from '../common/CssInJsComponents/ALinks';

interface IProps {
  user: CurrentUser;
}

const MainMenu: React.FC<IProps> = ({ user }) => {
  const usage = useUsageContext();
  const { email, defaultOrg, isAccessGranted } = user;
  const router = useRouter();

  const { t } = useTranslation('MainMenu');

  const onLogoutCurrent = () => api?.logout();

  const renderMainMenuItem = (label: string, href: Route, allowed = false, disabledTooltip?: string | JSX.Element) => {
    return allowed ? (
      <MainMenuItemWrapper highlight={router?.pathname?.startsWith(href?.pathname)}>
        <Link href={href} passHref>
          <MainMenuItemLink>{label}</MainMenuItemLink>
        </Link>
      </MainMenuItemWrapper>
    ) : (
      <MainMenuItemWrapperDisabled>
        <MainMenuItemDisabled>
          <Tooltip title={disabledTooltip}>
            <span>
              {label}
            </span>
          </Tooltip>
        </MainMenuItemDisabled>
      </MainMenuItemWrapperDisabled>
    )
  };

  const renderSupportLinks = () => {

    const supportLinks = {
      portal: EXTERNAL_LINKS.DOCS.LANDING,
      salesforce: EXTERNAL_LINKS.DOCS.SALESFORCE.GENERAL,
      restApi: EXTERNAL_LINKS.DOCS.REST_API.general,
      border: EXTERNAL_LINKS.DOCS.BORDER,
    };

    if (PortalConfig.isDefault() || PortalConfig.isDCC()) {
      return (
        <>
          <Divider />
          {Object.keys(supportLinks).map(k => (
            <SubmenuItem key={k} isRemote={true} href={supportLinks[k as keyof typeof supportLinks]}>
              {t(k)}
            </SubmenuItem>
          ))}
        </>
      );
    }
    return null;
  };

  const renderContactSupport = () => {
    if (PortalConfig.isDCC()) return <></>;
    if (PortalConfig.supportTicket !== '') {
      return (
        <>
          <Divider />
          <SubmenuItem href={PortalConfig.supportTicket} isRemote={true}>
            {t('contactSupport')}
          </SubmenuItem>
        </>
      );
    }
    return (
      <>
        <Divider />
        <SubmenuItem href={`mailto:${PortalConfig.supportEmail || ''}`} isRemote={true}>
          {t('contactSupport')}
        </SubmenuItem>
      </>
    );
  };

  const noUsageData = () => {
    return usage?.fetching || usage?.data?.length === 0;
  }

  const isUsageAllowed = () => {
    if (noUsageData()) return false;

    return isAccessGranted?.(Routes.dashboard.list().pathname);
  }

  return (
    <Wrapper>
      <Box display='grid' gridTemplateColumns='auto 1fr' gap={4} alignItems='center' mr={8}>
        <StyledLogo />
        <Typography fontWeight={800} fontSize={18}>{defaultOrg?.name}</Typography>
      </Box>

      <Box display='grid' gridAutoFlow='column' gap={3}>
        {PortalConfig.acl[Routes.dashboard.list().pathname] &&
          renderMainMenuItem(
            t('usage'),
            Routes.dashboard.list(),
            isUsageAllowed(),
            noUsageData() ?
              <>
                {t('noReportingData1')}
                <WhiteALink style={{ color: 'white' }} href={EXTERNAL_LINKS.DOCS.REST_API.crud} target='_blank' rel='noreferrer'>{t('noReportingData2')}</WhiteALink>
              </> :
              'Not available'
          )}

        {PortalConfig.acl[Routes.environment.list().pathname] &&
          renderMainMenuItem(
            t('environments'),
            Routes.environment.list(),
            isAccessGranted?.(Routes.environment.get().pathname),
          )}

        {PortalConfig.acl[Routes.company.list().pathname] &&
          renderMainMenuItem(
            t('organization'),
            Routes.company.list(),
            isAccessGranted?.(Routes.company.list().pathname),
          )}

        {PortalConfig.acl[Routes.member.list().pathname] &&
          renderMainMenuItem(
            t('users'),
            Routes.member.list(),
            isAccessGranted?.(Routes.member.list().pathname),
          )}

        <DropdownMenuItem label={t('support')}>
          <SubmenuItem isRemote={true} href={PortalConfig.urlToDocs}>
            {t('documentation')}
          </SubmenuItem>
          {renderSupportLinks()}
          {renderContactSupport()}
        </DropdownMenuItem>
      </Box>

      <ProfileMenuItem label={email} title={email} placement='bottom-end'>
        {PortalConfig.acl[Routes.profile.list().pathname] && (
          <SubmenuItem href={Routes.profile.list()} disabled={!isAccessGranted?.(Routes.profile.list().pathname)}>
            <ListItemText>{t('settings')}</ListItemText>
          </SubmenuItem>
        )}

        <SubmenuItem onClick={onLogoutCurrent}>{t('logout')}</SubmenuItem>
      </ProfileMenuItem>
    </Wrapper>
  );
};

const FlexRow = styled.div`
  display: flex;
  flex-flow: row;
  align-items: center;
`;

const MenuItemBaseCSS = css`
  display: flex;
  height: 90px;
  min-height: 90px;
  align-items: center;
  position: relative;
  &:hover span {
    color: ${colors.primary.main} !important;
    transition: color 500ms;
  }
`;

const DropdownMenuItem = styled(DropdownMenu)`
  ${MenuItemBaseCSS}
`;

const Wrapper = styled.div`
  display: flex;
  flex-flow: row;
  height: 90px;
  min-height: 90px;
  min-width: 100%;
  align-items: center;
  gap: 24px;
`;

const MainMenuItemWrapper = styled(FlexRow) <{ highlight: boolean }>`
  ${MenuItemBaseCSS}
  cursor: pointer;
  line-height: 91px;
  &:before {
    transition: background-color 500ms;
    content: '';
    width: 100%;
    height: 2px;
    background-color: transparent;
    position: absolute;
    top: 60px;
    left: 0;
  }
  ${({ highlight }) => highlight ? `
  &:before {
    & > span,
    & > a {
      transition: color 500ms;
    }
    background-color: ${alpha(colors.primary.main, 0.6)};
  }
  ` : ``}
`;

const MainMenuItemWrapperDisabled = styled(FlexRow)`
  display: flex;
  height: 90px;
  cursor: not-allowed;
  min-height: 90px;
  align-items: center;
  position: relative;
  line-height: 91px;
`;

const ProfileMenuItem = styled(DropdownMenuItem)`
  margin-left: auto;
`;

const MainMenuItemLink = styled.span`
  text-decoration: none;
  height: 100%;
  display: inline-block;
  color: ${colors.text.primary};
`;

const MainMenuItemDisabled = styled.span`
  text-decoration: none;
  display: inline-block;
  color: ${alpha(colors.text.primary, 0.5)};
`;

const StyledLogo = styled(Logo)`
  height: 30px;
  width: 30px;
  max-height: 30px;
  margin-right: -8px;
`;

export default MainMenu;
