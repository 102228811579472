import { CountryCode } from './country';
import { CountryTenant } from './infraJson';
import { ServiceTypesEnum } from './services';
import { TableSortOrder } from './table';

export interface ILogsHitSource {
  "@timestamp": string;
  env: string;
  env_id: string;
  event: {
    ingested: string;
  },
  "fluentd.host": string;
  host: string;
  "incountry.client": string;
  "incountry.country": string;
  "incountry.increment": string;
  "incountry.server_role": string;
  log_type: string;
  message: string;
  request_id: string;
  request_id_internal: string;
  report_id?: string;
  message_id?: string;
  request_method: string;
  request_url: string;
  "service.component": string;
  "service.name": string;
  severity: string;
  "proxy.public.severity": string;
  tenant: string;
  type: string;
}

export interface ILogsHit {
  id: string;
  source: ILogsHitSource;
}

export interface ILogs {
  total: number;
  hits: ILogsHit[];
  PUBLIC_LOGS_EXPORT_ROWS_LIMIT: number;
}

interface ILogServiceItem {
  id: string;
  name: string;
}

interface ILogService {
  service_id: string;
  service_code: ServiceTypesEnum;
  items: ILogServiceItem[];
}

export interface ILogServicesData {
  country_code: CountryCode;
  customer_code: CountryTenant;
  increment: string;
  services: ILogService[];
}

export enum LogsSearchType {
  requestID = 'request_id',
  requestIDInternal = 'request_id_internal',
  messageID = 'message_id',
  reportID = 'report_id',
}

export interface ILogsQuery {
  country_code: string;
  service_id: string;
  service_type_code: ServiceTypesEnum;
  item_id: string;
  customer_code: string;
  increment: string;
  search_by: string;
  search_value: string;
  sort_by?: string;
  sort_order?: TableSortOrder;
  start_date?: string;
  end_date?: string;
  from: number;
  size: number;
  download?: boolean;
}

export type LogsSearchFunc = (envId?: string, countryCode?: CountryCode, customerCode?: CountryTenant, increment?: string, serviceId?: string, serviceTypeCode?: ServiceTypesEnum, itemId?: string, searchBy?: LogsSearchType, searchValue?: string, sortBy?: string, sortOrder?: TableSortOrder, page?: string, rowsPerPage?: string, startDate?: string, endDate?: string, download?: boolean) => void;
