import getConfig from 'next/config';

import { NextGetConfig } from 'app/types/nextjs';

const {
  publicRuntimeConfig: { IC_WD_OAUTH_API },
} = getConfig() as NextGetConfig;

export const IDPEndpoint = IC_WD_OAUTH_API;

export const isProduction = (): boolean => process.env.NODE_ENV === 'production';
export const isDevelopment = (): boolean => process.env.NODE_ENV === 'development';
