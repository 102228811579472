import {
  BORDER_CONFIG_JSON_EXAMPLE,
  METRICS_UNIT,
  RESIDENT_FUNCTIONS_TOKEN,
  RESIDENT_FUNCTIONS_TOKEN_CLIENT_ID,
  RESIDENT_FUNCTIONS_TOKEN_TTL,
  VALUE_DELIMETER,
} from 'app/utils/constants/localStorage';
import { isBrowser } from 'app/utils/misc';
import { DataMeasurmentUnit } from 'app/utils/strings';

export const setMetricsUnitToStorage = (metric: DataMeasurmentUnit): void =>
  isBrowser ? localStorage.setItem(METRICS_UNIT, metric) : undefined;
export const getMetricsUnitFromStorage = () =>
  isBrowser ? (localStorage.getItem(METRICS_UNIT) as DataMeasurmentUnit) : null;

export const setResidentFunctionsToken = (token: string): void =>
  isBrowser ? sessionStorage.setItem(RESIDENT_FUNCTIONS_TOKEN, token) : undefined;
export const getResidentFunctionsToken = (): string | null =>
  isBrowser ? sessionStorage.getItem(RESIDENT_FUNCTIONS_TOKEN) : null;

export const setResidentFunctionsTokenClientId = (clientId: string): void =>
  isBrowser ? sessionStorage.setItem(RESIDENT_FUNCTIONS_TOKEN_CLIENT_ID, clientId) : undefined;
export const getResidentFunctionsTokenClientId = (): string | null =>
  isBrowser ? sessionStorage.getItem(RESIDENT_FUNCTIONS_TOKEN_CLIENT_ID) : null;

export const setResidentFunctionsTokenTtl = (ttl: string): void =>
  isBrowser ? sessionStorage.setItem(RESIDENT_FUNCTIONS_TOKEN_TTL, ttl) : undefined;
export const getResidentFunctionsTokenTtl = (): string | null =>
  isBrowser ? sessionStorage.getItem(RESIDENT_FUNCTIONS_TOKEN_TTL) : null;

export const clearResidentFunctionsTokenInfo = (): void => {
  if (!isBrowser) return;
  sessionStorage.removeItem(RESIDENT_FUNCTIONS_TOKEN);
  sessionStorage.removeItem(RESIDENT_FUNCTIONS_TOKEN_CLIENT_ID);
  sessionStorage.removeItem(RESIDENT_FUNCTIONS_TOKEN_TTL);
};

export const setBorderJsonExample = (envId: string, type: 'REQUEST' | 'RESPONSE', method: string, path: string, example: string): void => {
  if (isBrowser) {
    const storageKey = [BORDER_CONFIG_JSON_EXAMPLE, envId, type, method, path].join(VALUE_DELIMETER);
    localStorage.setItem(storageKey, example)
  }
}

export const getBorderJsonExample = (envId: string, type: 'REQUEST' | 'RESPONSE', method: string, path: string) => {
  if (isBrowser) {
    const storageKey = [BORDER_CONFIG_JSON_EXAMPLE, envId, type, method, path].join(VALUE_DELIMETER);
    return localStorage.getItem(storageKey)
  }
  return null;
}
