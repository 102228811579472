import ResidentFunction from 'app/api/models/ResidentFunction';

export interface IResidentFunctionList {
  functionName: string;
  createdAt: string;
  updatedAt: string;
}

export interface IResidentFunctionOptions {
  country: string;
  forceUpdate: boolean;
}

export interface ResidentFunctionParamFieldsInterface {
  key: string;
  value: string;
}

export interface ResidentFunctionExecuteResponse {
  exitCode?: number;
  exitSignal?: string;
  result?: string;
  stdout?: string;
  stderr?: string;
  duration: number;
}

export interface ResidentFunctionResponseInterface {
  script_name: string;
  script_body?: string;
  created_at: string;
  updated_at: string;
  publish_version: string;
}

export interface ResidentFunctionsResponseMetaInterface {
  count: number;
  limit: number;
  offset: number;
  total: number;
  expired_at?: string;
};

export interface ResidentFunctionsResponseInterface {
  data: ResidentFunctionResponseInterface[];
  meta: ResidentFunctionsResponseMetaInterface;
  is_cached?: boolean;
}

export interface ResidentFunctionsInterface {
  data: ResidentFunction[];
  meta: ResidentFunctionsResponseMetaInterface;
}

export interface ResidentFunctionsRequestInterface {
  limit: number;
  offset: number;
  clientId: string;
  clientSecret: string;
}

export interface ResidentFunctionRequestInterface {
  functionName: string;
  clientId: string;
  clientSecret: string;
}

export interface ResidentFunctionExecuteRequestInterface {
  scriptName: string;
  options: {
    country: string;
  };
  scriptParams: { [key: string]: string };
}

export interface ResidentFunctionPublishRequestInterface {
  scriptName: string;
  scriptBody: string;
  options: {
    country: string;
    forceUpdate: boolean;
  };
}

export enum ResidentFunctionsRequestStates {
  success = 'success',
  error = 'error',
  fetching = 'fetching',
};

export enum ResidentFunctionsCacheRequestMethods {
  rfCache = 'rf_cache',
  rfCacheGet = 'rf_cache_get',
  rfCacheClear = 'rf_cache_clear',
};
