import PortalConfig from 'app/api/models/PortalConfig';
import { CountryCode } from 'app/types/country';

export interface EncryptionKeyResponse {
  $TypeID?: string;
  id: string;
  name: string;
  type: EncryptionKeyType;
  version: number;
  country: CountryCode;
  created_at: string;
  updated_at: string;
  is_active: boolean;
  rotation_period?: number;
  company: {
    id: string;
    name: string;
  };
  user: {
    email: string;
    id: string;
    username: string;
  };
  environment: {
    id: string;
    name: string;
  };
}

export interface EncryptionKeyRotationPeriodInterface {
  name: string;
  value: number;
}

export enum EncryptionKeyRotationPeriodValues {
  'MONTH' = 30,
  'TWO_MONTH' = 60,
  'HALF_YEAR' = 180,
  'YEAR' = 360,
}

export enum EncryptionKeyTypesEnum {
  INCOUNTRY_MANAGED = 'incountry',
  MANUAL_MANAGED_AWS = 'aws',
}

export type EncryptionKeyType = EncryptionKeyTypesEnum.INCOUNTRY_MANAGED | EncryptionKeyTypesEnum.MANUAL_MANAGED_AWS;

export const EncryptionKeysTypes: EncryptionKeyType[] = [
  EncryptionKeyTypesEnum.INCOUNTRY_MANAGED,
  EncryptionKeyTypesEnum.MANUAL_MANAGED_AWS,
]?.filter(types => PortalConfig.encryptionKeysTypesBlacklist?.filter(configTypes => configTypes === types).length === 0);
