import { BorderPlugins } from 'app/types/border';
import { SchemaDataTypeEnum, SchemaFieldTypeEnum } from 'app/types/environments';
import { PoliciesFieldProtectionMode } from 'app/types/policies';
import { values } from 'lodash';

/* eslint-disable sonarjs/no-duplicate-string */
export const translation = {
  SignIn: {
    signIn: 'Sign In',
    signUp: 'Sign Up',
    forgotPassword: 'Forgot password?',
  },
  ProfileAccountSettings: {
    account: 'Account',
    resetPassword: 'Reset password',
    type: 'Type',
  },
  ProfileSessions: {
    terminateSession: 'Terminate session',
    logout: 'Log out',
  },
  ProfileApplication: {
    mfaAuthentication: 'Two-factor authentication',
    email: 'Email',
    authenticationApp: 'Authentication app',
    hintApp:
      'You can switch to authorization of your actions on Portal through the mobile authentication app (e.g. Google Authenticator). Every half minute the app generates a verification code that you can use to confirm your actions on {{brandingTitle}} Portal.',
    hintEmail:
      'You can switch to authorization of your actions on Portal through emails containing a verification code. Portal will send an email with a verification code every time you need to authorize a performed action on {{brandingTitle}} Portal.',
    switchToEmail: 'Switch to email authentication',
    switchToApp: 'Switch to authentication App',
    current: 'Current',
  },
  ProfilePage: {
    settings: 'Settings',
    user: 'User',
  },
  ConfirmationCodeComponent: {
    enterTotpCode: 'Enter the code from the authentication app',
    enterEmailCode: 'Enter the code from your email',
    useVerificationCode: 'Use verification code',
    enterRecoveryCode: 'Enter recovery code',
    verificationCode: 'Verification Code',
    confirmationNotice: 'Please verify the entered information one more time before going further.',
    verificationCodeSent: 'Verification code has been sent to your email',
    enterAppVerificationCode: 'Enter the verification code from the mobile authentication app',
    verificationCodeAlreadySent:
      'The verification code has already been sent to you. Please check your email box and enter the recently received verification code',
  },
  Errors: {
    verificationCodeIsInvalid: 'Verification code is invalid',
  },
  EnvironmentsPage: {
    scheduledForDeletion: 'Pending deletion',
    name: 'Name',
    type: 'Type',
    id: 'ID',
    edit: 'Edit',
    delete: 'Delete',
    environments: 'Environments',
    restoreEnvironment: 'Restore environment',
    SDKs: 'Storage Credentials',
    services: 'Services',
    addEnvironment: 'Add environment',
    title: 'What is environment?',
    maxEnvironmentLimitReached: 'Maximum limit of environments has been reached',
    showPendingRemoval: 'Show environments pending removal',
    description:
      'Environment is a data storage entity that determines the operational context for data residency services provided by the {{brandingTitle}} platform. The environment allows you to regulate access to your data stores on the {{brandingTitle}} platform.',
    subDescription:
      'By default, {{brandingTitle}} Portal creates a default environment of the Development type. You can create additional environments as needed. Within each environment, you can have multiple SDKs and services that you can use for storing regulated data on the {{brandingTitle}} platform.',
    restoreEnvitonmentAlert:
      'The deletion of this environment is scheduled for {{date}}. All storage credentials, services, encryption keys, and records stored within this environment are temporarily locked. They will be automatically wiped along with the environment. If the environment was scheduled for deletion accidentally, you can restore it by clicking "Restore environment" button. The complete deletion of the environment may require a significant amount of time, depending on the amount of stored data and the current load. Once the environment and all its associated data are deleted, you will receive an email notification.',
    manageConfigurations: 'Export/Import environment configuration',
    Staging: 'Stage',
    cantRestoreEnvironment: 'Cannot restore environment: you have reached the limit of {{envLimits}} environments. Please remove an existing environment and try again.'
  },
  EnvironmentCreatePage: {
    myCompany: 'My organization',
    environments: 'Environments',
    createNewEnvironment: 'Add environment',
    create: 'Create',
    name: 'Name',
    parameters: 'Parameters',
    verification: 'Please verify the values',
    labelDesc: 'Enter the name of the environment to identify it among other environments.',
    type: 'Type',
    typeDesc:
      'Select the type of environment which you want to set up (Dev, Test, Stage, Integration, U.A.T., or Production).',
    confirmation: 'Verification',
    confirmationDesc: 'Enter the verification code from your email or authentication app.',
    title: 'What is environment?',
    description:
      'Environment is a term in {{brandingTitle}} Platform which determines the operational context for data residency services. The environment allows you to regulate access to your data stores in {{brandingTitle}} Platform.',
    next: 'Next',
    nameDesc: 'Enter the name of the environment to identify it among other environments.',
  },
  CreateEnvironmentForm: {
    type: 'Type',
    name: 'Name',
    createNewEnvironment: 'Add environment',
    updateEnvironment: 'Update environment',
    back: 'Back',
    Staging: 'Stage',
  },
  EnvironmentPage: {
    addEnvironment: 'Add environment',
    activateCountry: 'Activate country',
    myCompany: 'My organization',
    environmentSetup: 'Environment setup',
    environments: 'Environments',
    createNewEnvironment: 'Add environment',
    SDKs: 'Storage Credentials',
    activeSdks: 'active Storage Credentials',
    generateSdk: 'Generate Storage Credentials',
    services: 'Services',
    servicesCreated: 'services created',
    createService: 'Create service',
    encryptionKeys: 'Encryption Keys',
    keysCreated: 'keys created',
    name: 'Name',
    type: 'Type',
    edit: 'Edit',
    delete: 'Delete',
    country: 'Country',
    maxSdkLimitReached: 'Maximum limit of SDKs has been reached',
    descriptionTitle: 'What is environment?',
    description:
      'Environment is a term in {{brandingTitle}} Platform which determines the operational context for data residency services. The environment allows you to regulate access to your data stores in {{brandingTitle}} Platform.',
    id: 'ID',
    restoreEnvironment: 'Restore environment',
    keyAliasMapping: 'Schema',
    configure: 'Configure',
    cantRestoreEnvironment: 'Cannot restore environment: you have reached the limit of {{envLimits}} environments. Please remove an existing environment and try again.',
    manageConfigurations: 'Export/Import environment configuration',
    accessPolicies: 'Access Policies',
    logs: 'Logs',
  },
  EnvironmentUpdatePage: {
    myCompany: 'My organization',
    environments: 'Environments',
    updateEnvironment: 'Update environment',
    update: 'Update',
    parameters: 'Parameters',
    verification: 'Please verify the values',
    descriptionTitle: 'What is environment?',
    description:
      'Environment is a term in {{brandingTitle}} Platform which determines the operational context for data residency services. The environment allows you to regulate access to your data stores in {{brandingTitle}} Platform.',
    confirmation: 'Verification',
    confirmationDesc: 'Enter verification code from your email or authenticator app.',
    next: 'Next',
  },
  EnvironmentsDeletePage: {
    myCompany: 'My organization',
    environments: 'Environments',
    deleteEnvironment: 'Delete environment',
    delete: 'Delete',
    verification: 'Please verify the values',
    parameters: 'Parameters',
    areYouSure: 'Do you really want to delete this environment',
    thisWillDelete:
      'This action will schedule `{{name}}` environment to be deleted. \nAll the Storage Credentials, services, encryption keys, and records stored within this environment will be deleted. If the environment was scheduled for deletion accidentally, you can restore it by clicking Restore button on environment page.',
  },
  CreateBillingCustomerForm: {
    creditCardInformation: 'Credit card information',
    back: 'Back',
    upgrade: 'Upgrade',
    holderName: 'Holder name',
    email: 'Email',
    subscriptionPlan: 'Subscription plan',
    stripeNotLoadedError: "Stripe.js hasn't loaded yet",
    subscriptionWasSuccessfullyCreated: 'The subscription has been created successfully',
    creditCardNumber: 'Credit card number',
    expirationDate: 'Expiration date',
    cvc: 'CVC',
  },
  SdksPage: {
    SDKs: 'Storage Credentials',
    environments: 'Environments',
    createNewEnvironment: 'Add environment',
    name: 'Name',
    created: 'Created',
    changeSdkLabel: 'Rename Storage Credentials',
    deleteSdk: 'Delete Storage Credentials',
    createNewSdk: 'Add Storage Credentials',
    myCompany: 'My organization',
    pageTitle: 'Working with Storage Credentials',
    maxSdkLimitReached: 'Max SDK limit reached',
    accessPolicy: 'Access Policy',
    pageDescription:
      'Storage Credentials are a pair of Client ID and Client Secret that you can use to get access to data residency services of the {{brandingTitle}} platform within the allocated environment. All Storage Credentials are associated with a specific environment within which they were created.',
  },
  SdkCreatePage: {
    newSdk: 'Add Storage Credentials',
    environments: 'Environments',
    name: 'Name',
    back: 'Back',
    next: 'Next',
    myCompany: 'My organization',
    parameters: 'Parameters',
    SDKs: 'Storage Credentials',
    verification: 'Please verify the values',
    create: 'Create',
    credentials: 'Credentials',
    gettingStarted: 'Getting started',
    integrateSdk: 'Integrate SDK into your application',
    enterSdkLabel: 'Enter the name for Storage Credentials to easily distinguish one pair of credentials from another.',
    enterVerificationCode:
      'A verification code protects from an accident removal of an SDK. You can change parameters of verification codes on the Profile page.',
    pageTitle: 'Working with Storage Credentials',
    pageDescription:
      'Storage Credentials are a pair of Client ID and Client Secret that you can use to get access to data residency services of the {{brandingTitle}} platform within the allocated environment. All Storage Credentials are associated with a specific environment within which they were created.',
  },
  SdkUpdatePage: {
    SDKs: 'Storage Credentials',
    environments: 'Environments',
    name: 'Name',
    back: 'Back',
    next: 'Next',
    myCompany: 'My organization',
    parameters: 'Parameters',
    enterSdkLabel: 'Enter the name for Storage Credentials to easily distinguish one pair of credentials from another.',
    verification: 'Please verify the values',
    update: 'Update',
    renameSdkCredentials: 'Rename Storage Credentials',
    credentials: 'Credentials',
    pageTitle: 'Working with Storage Credentials',
    pageDescription:
      'Storage Credentials are a pair of Client ID and Client Secret that you can use to get access to data residency services of the {{brandingTitle}} platform within the allocated environment. All Storage Credentials are associated with a specific environment within which they were created.',
    changeLabel: 'Changing SDK Name will not change Client ID or Secret.',
  },
  SdkDeletePage: {
    SDKs: 'Storage Credentials',
    environments: 'Environments',
    myCompany: 'My organization',
    name: 'Name',
    back: 'Back',
    confirmation: 'Verification',
    confirm: 'Confirm',
    verification: 'Please verify the values',
    parameters: 'Parameters',
    next: 'Next',
    delete: 'Delete',
    deleteSdk: 'Delete Storage Credentials',
    areYouSure: 'Do you really want to delete these Storage Credentials?',
    thisWillDelete:
      'This will permanently delete the `{{name}}` Storage Credentials.\nThis action cannot be undone.\n\nRemoval of Storage Credentials may partially or fully break operation of your service or system that uses the {{brandingTitle}} SDK as data requests will not be authorized.',
  },
  CreateSdkForm: {
    name: 'Name',
    required: 'Required',
    accessPolicy: 'Access Policy',
  },
  UpdateSdkForm: {
    name: 'Name',
    back: 'Back',
    required: 'Required',
  },
  ShowSdkCredentials: {
    sdkName: 'Name',
    sdkId: 'Client ID',
    sdkSecret: 'Client secret',
    environmentId: 'Environment ID',
    copy: 'Copy',
    close: 'Close',
    copyToClipboard: 'Copy to clipboard',
    downloadText: 'Download as text',
    makeSureToCopy: 'Make sure to copy this information now. You will no longer be able to see it again!',
    environmentIdIsCopied: 'Environment ID copied successfully',
    authUrl: 'Auth URL',
    popApiUrl: 'PoP API URL',
    restApiUrl: 'REST API URL',
  },
  CompanyPage: {
    organization: 'Organization',
    companyName: 'Organization name',
    country: 'Country',
    myCompany: 'My organization',
    update: 'Update',
    subscriptionPlans: 'Subscription plans',
    membersAndInvites: 'Members & invites',
    transferOwnership: 'Transfer ownership',
    companyOwnership: 'Organization ownership',
    manageMembers: 'Manage members',
    changePlan: 'Change subscription plan',
    members: 'Members',
    restoreMessage: 'The deletion of this organization is scheduled on <strong>{{date}}</strong>. If you want to cancel this process, click Restore button.',
    deleteMessage: `Deleting your organization will schedule it for permanent removal. All data associated with the organization will be erased during this process. You can cancel the deletion at any time before it's finalized. Click "Delete" to begin the process.`,
    delete: 'Delete',
    restore: 'Restore',
    confirm: 'Confirm',
    cancel: 'Cancel',
    organizationSettingsSubtitle: 'Manage your organization settings and preferences.',
    copyOrgId: 'Copy Organization ID',
    settings: 'Settings',
    orgRemoval: 'Organization deletion',
    successfullyCopiedToClipboard: 'Organization ID copied to clipboard'
  },
  CompanyUpdatePage: {
    updateCompanySettings: 'Update organization',
    update: 'Update',
    parameters: 'Parameters',
    verification: 'Please verify the values',
    next: 'Next',
    cancel: 'Cancel',
    pageDescTitle: 'Your organization',
    pageDescDetails:
      'You can modify the organization which you represent and the country where you are located. Changing these parameters does not impact the overall work with the {{brandingTitle}} platform.',
    myCompany: 'My organization',
  },
  ServicesPage: {
    myCompany: 'My organization',
    services: 'Services',
    environments: 'Environments',
    createNewEnvironment: 'Add environment',
    residentFunctions: 'Resident functions',
    manageConfigurations: 'Manage endpoints',
    name: 'Name',
    created: 'Created',
    type: 'Type',
    country: 'Country',
    renewService: 'Renew service',
    deleteService: 'Delete service',
    createNewService: 'Add service',
    details: 'Service details',
    showCode: 'Show code',
    addService: 'Add service',
    editService: 'Edit service',
    pageTitle: 'Working with services',
    salesforce: 'Salesforce',
    pageDescription:
      'A service is an entity which represents a connection point which can be used to integrate your system with other components of the {{brandingTitle}} Platform.',
    uploadCertificate: 'Upload certificate',
    replaceCertificate: 'Replace certificate',
    noAvailableService: 'No available services for creation',
    viewLogs: 'View logs',
    accessPolicy: 'Access Policy',
    manageCredentials: 'Manage credentials',
  },
  ServiceCreatePage: {
    myCompany: 'My organization',
    services: 'Services',
    newService: 'Add service',
    environments: 'Environments',
    environment: 'Environment',
    create: 'Create',
    pageTitle: 'Working with services',
    pageDescription: `A service is an entity which represents a connection point which can be used to integrate your system
      with other components of the {{brandingTitle}} Platform.`,
    parameters: 'Parameters',
    next: 'Next',
    verification: 'Please verify the values',
    credentials: 'Credentials',
    enterVerificationCode:
      'Please, check the service parameters one more time and confirm them with a verification code',
    type: 'Type',
    name: 'Name',
    country: 'Country',
    alibabaCloudOss: 'Alibaba Cloud OSS',
    paramDescription1:
      'Select the service type from the list. You will no longer be able to change the type of service after creation.',
    paramDescription2: 'Enter the name of the service.',
    paramDescription3: 'Select the country where service setup.',
    paramDescription4:
      'Move the toggle right to activate the object storage service to store attachments on Alibaba Cloud.',
    paramDescription5:
      'The environment defines the operational context for storing regulated data on the {{brandingTitle}} platform. You can use environments to regulate access to stored data.',
    instructions: 'Instructions',
    setupBorderConfiguration: 'Setup endpoint',
    publishResidentFunction: 'Publish resident function',
    restapiGuideTitle: 'Communicate regulated data through the REST API',
    salesforceGuideTitle: 'Integrate Salesforce with {{brandingTitle}}',
    gettingStarted: 'Getting started',
    addService: 'Add Service',
    uploadCertificate: 'Upload certificate',
    borderServiceCreated: 'Web service is created',
    serviceSuccessfullyCreated: '{{serviceName}} service successfully added',
  },
  ServiceDeletePage: {
    country: 'Country',
    myCompany: 'My organization',
    deleteService: 'Delete service',
    services: 'Services',
    environments: 'Environments',
    verification: 'Please verify the values',
    parameters: 'Parameters',
    delete: 'Delete',
    areYouSure: 'Are you sure you want to delete service?',
    thisWillDeleteService: 'This will permanently delete {{serviceName}} service. This action cannot be undone.',
    pageTitle: 'Working with services',
    pageDescription: `A service is an entity which represents a connection point which can be used to integrate your system
      with other components of the {{brandingTitle}} Platform.`,
  },
  ServiceRenewPage: {
    renewService: 'Renew service',
    services: 'Services',
    environments: 'Environments',
    renew: 'Renew',
    areYouSure: 'Do you really want to renew this service?',
    actionCantBeUndone:
      'Renewing service credentials will create new ones and invalidate the old ones.',
    pageTitle: 'Working with Services',
    pageDescription: `A service is an entity which represents a connection point which can be used to integrate your system
      with other components of the {{brandingTitle}} Platform.`,
    parameters: 'Parameters',
    verification: 'Please verify the values',
    credentials: 'Credentials',
    close: 'Close',
    back: 'Back',
    country: 'Country',
    publishResidentFunction: 'Publish resident function',
    addResidentFunctions: 'Add Resident Functions',
    serviceCredsWarning: 'These are service credentials. Please use them only when you need to manage resident functions through the {{brandingTitle}} Portal.',
    serviceSdkId: 'Service client ID',
    serviceSdkSecret: 'Service client secret',
    frontendCredsDesc: 'To call Resident Functions from your frontend application, use the following credentials:',
    makeSureToCopy: 'Make sure to copy this information now. You will no longer be able to see it again!',
    sdkId: 'Client ID',
    sdkSecret: 'Client secret',
    environmentId: 'Environment ID',
    restApiAddress: 'Rest API address',
  },
  CreateServiceForm: {
    type: 'Type',
    name: 'Name',
    country: 'Country',
    required: 'This field is required',
    maxLength: 'The maximum length of an organization name is {{maxLength}} characters',
    environmentId: 'Environment ID',
    createNewEnvironment: 'Add environment',
    environment: 'Environment',
    alicloudOss: 'Alibaba Cloud OSS',
    mt: 'MULTI-TENANT',
    st: 'SINGLE-TENANT',
    webServices: 'Web Services',
    webServicesBorderDesc: 'With Border, you can quickly and easily redact and unredact regulated data passed through the browser with minor changes in the code base of your web application.',
    restApiTitle: 'REST API',
    restApiDesc: 'With the set of RESTful methods, you can perform CRUD operations on regulated records, as well as create aggregated reports and manage attachments.',
    residentFunctionsTitle: 'Resident Functions',
    residentFunctionsDesc: 'With resident functions, you can perform compliant validation of regulated and sensitive values within their domestic borders.',
    emailTitle: 'Email',
    emailDesc: 'With Email, you can handle inbound and outbound emails containing regulated data in a compliant way.',
    paymentsTitle: 'Payments',
    paymentsDesc: 'With Payments, you can securely store payment card data in its country of origin and protect payment transactions against critical vulnerabilities.',
    salesforceTitle: 'Salesforce',
    salesforceDesc: 'You can easily integrate Salesforce with the DRaaS platform and manage regulated records in a compliant way with preserving the same user experience.',
    restapiWebTitle: 'Frontend REST API',
    restapiWebDesc: 'Simplified authorization on REST API.',
    serviceAlreadyCreated: 'Service of this type already exists in this environment.',
    webServicesAwsEncKeysError: 'Creation of this service is not allowed with AWS encryption keys.',
    disabledByConfig: 'This service is disabled by Portal configuration. Contact administrator for more information.',
    serviceUsed:
      '{{type}} service for the {{country}} already exists. You can create only one service of each type for one country within the same environment.',
  },
  EditSalesforceServicePage: {
    SDKs: 'Storage Credentials',
    environments: 'Environments',
    name: 'Name',
    back: 'Back',
    next: 'Next',
    myCompany: 'My organization',
    parameters: 'Parameters',
    enterSdkLabel: 'Enter the name for Storage Credentials to easily distinguish one pair of credentials from another.',
    verification: 'Please verify the values',
    update: 'Update',
    services: 'Services',
    editService: 'Edit service',
    credentials: 'Credentials',
    pageTitle: 'Working with Storage Credentials',
    pageDescription:
      'Storage Credentials are a pair of Client ID and Client Secret that you can use to get access to data residency services of the {{brandingTitle}} platform within the allocated environment. All Storage Credentials are associated with a specific environment within which they were created.',
    changeLabel: 'Changing SDK Name will not change Client ID or Secret.',
    type: 'Type',
    country: 'Country',
    environment: 'Environment',
    alibabaCloudOss: 'Alibaba Cloud OSS',
    paramDescription1:
      'Select the service type from the list. You will no longer be able to change the type of service after creation.',
    paramDescription2: 'Enter the name of the service.',
    paramDescription3: 'Select the country where service setup.',
    paramDescription4:
      'Move the toggle right to activate the object storage service to store attachments on Alibaba Cloud.',
    paramDescription5:
      'The environment defines the operational context for storing regulated data on the {{brandingTitle}} platform. You can use environments to regulate access to stored data.',
  },
  ShowServiceCredentials: {
    sdkName: 'Name',
    sdkId: 'Client ID',
    sdkSecret: 'Client secret',
    environmentId: 'Environment ID',
    restApiAddress: 'Rest API address',
    proxyAddress: 'Proxy address',
    copy: 'Copy',
    copyToClipboard: 'Copy to clipboard',
    downloadText: 'Download as text',
    cloakCredentials: 'Cloak credentials',
    makeSureToCopy: 'Make sure to copy this information now. You will no longer be able to see it again!',
    environmentIdIsCopied: 'Environment ID copied successfully',
    subjectClaim: 'Subject claim',
    salesforceAlarmText:
      'Please use the subject claim to define OAuth authorization in Salesforce when setting up a connection to the {{brandingTitle}} platform.',
    ossAlert1: 'To finalize activation of the object storage service (OSS) for this service, please contact ',
    ossAlert2Ali: 'Alibaba Cloud team',
    ossAlert2Dcc: 'Digital China team',
    ossAlert3: ' and provide them with the following subject claim ',
    filterEndpointURL: 'Access control URL',
    authUrl: 'Auth URL',
  },
  NoEnvironmentsPlaceholder: {
    addEnvironment: 'Add environment',
    noEnvironments: 'No environments available',
    noEnvironmentsNotification:
      'It seems that you have not created any environments yet. Please add at least one environment to proceed with the management of SDKs, services, and in-app extensions on {{brandingTitle}} Portal.',
  },
  DashboardEnvPage: {
    dashboard: 'Dashboard',
    gettingStarted: 'Getting started?',
    professional: 'Professional',
    bandwidth: 'Bandwidth',
    transactions: 'Transactions',
    changeSubscriptionPlan: 'Change subscription plan',
    companySettings: 'Organization settings',
    logout: 'Log out',
    profile: 'Profile',
    dataDistribution: 'Usage',
    noDataProvided: 'No data provided',
    files: 'Files',
    records: 'Records',
    filesSize: 'Files size',
    recordsSize: 'Records size',
    selectedEnvironment: 'Environment',
    createNewEnvironment: 'Add environment',
    dataType: 'Data type',
    refresh: 'Refresh',
  },
  DashboardUsageTable: {
    country: 'Country',
    deviation: 'Deviation',
    bandwidth: 'Bandwidth',
    numberOfTransactions: 'Transactions',
    numberOfReads: 'Reads',
    numberOfWrites: 'Writes',
    numberOfDeletes: 'Deletes',
    numberOfErrors: 'Errors',
    usageWarning: 'Reporting data on transactions is updated every 5 minutes. The recently performed transactions may appear with slight delays.',

  },
  DashboardUsageTotalsTable: {
    country: 'Country',
    files: 'Files',
    records: 'Records',
    recordsSize: 'Records size',
    filesSize: 'Files size',
  },
  DatePickerComponent: {
    dataActual: 'End date',
    pickADate: 'Pick a date',
    datePickerHelperText: 'Reporting data is calculated once a day. Reporting for the current date (today) will be available tomorrow.'
  },
  DateTimeRangePicker: {
    startDate: 'Start date',
    endDate: 'End date',
    pickADateRange: 'Pick a date range',
    dateTimePickerHelperText: 'Report is generated for the UTC+00:00 time zone.'
  },
  Map: {
    noDataProvided: 'No data provided',
    usageWarning: 'Reporting data on transactions is updated every 5 minutes. The recently performed transactions may appear with slight delays.',
  },
  MapTooltip: {
    bandwidth: 'Bandwidth',
    transactions: 'Transactions',
    country: 'Country',
    files: 'Files',
    records: 'Records',
    recordsSize: 'Records size',
    filesSize: 'Files size',
  },
  EncryptionKeysPage: {
    version: 'Version',
    myCompany: 'My organization',
    country: 'Country',
    encryptionKeys: 'Encryption Keys',
    month_one: '{{count}} month',
    month_other: '{{count}} months',
    createNewEnvironment: 'Add environment',
    environments: 'Environments',
    createNewEncryptionKey: 'Add encryption key',
    sseWarning:
      'To activate the server-side encryption (SSE) when using the {{brandingTitle}} SDK, please create an encryption key for the country and add the `X-Encrypted-Storage: true` header into your data requests.',
    status: 'Status',
    created: 'Created',
    active: 'Active',
    deprecated: 'Deprecated',
    name: 'Name',
    type: 'Type',
    month: 'Month',
    incountryKeyMgmt: '{{brandingTitle}} managed',
    manualKeyMgmtAws: 'Manual: AWS',
    createNewVersion: 'Add version',
    pageTitle: 'Working with encryption',
    rotationPeriod: 'Rotation period',
    pageDescription:
      'The {{brandingTitle}} Portal lets you create encryption keys that are used for data encryption by {{brandingTitle}} REST API. You need to generate new secret keys for security purposes when you are extensively writing data records to {{brandingTitle}} Platform.',
    settings: 'Settings',
  },
  EncryptionKeyCreatePage: {
    create: 'Create',
    myCompany: 'My organization',
    environments: 'Environments',
    encryptionKeys: 'Encryption Keys',
    keyType: 'Key type',
    verification: 'Please verify the values',
    parameters: 'Parameters',
    newVersionOfKey: 'Add new version of encryption key',
    newEncryptionKey: 'Add encryption key',
    newKeyVersionConfirmation:
      'Creating a new version will mark all other keys as deprecated. New records will be encrypted using this new version of the encryption. Other encryption keys will be preserved for decryption of old records.',
    chooseKeyManagementType:
      'Please choose which type of key management to use for the current environment and country',
    encryptionKeysTitle: 'Working with encryption',
    encryptionKeysDescription:
      'The {{brandingTitle}} Portal lets you create encryption keys that are used for data encryption by {{brandingTitle}} REST API. You need to generate new encryption keys for security purposes when you are extensively writing data records to the {{brandingTitle}} Platform.',
    next: 'Next',
    country: 'Country'
  },
  EncryptionKeyUpdatePage: {
    update: 'Update',
    myCompany: 'My organization',
    environments: 'Environments',
    encryptionKeys: 'Encryption Keys',
    verification: 'Please verify the values',
    parameters: 'Parameters',
    encryptionKeyName: 'Name',
    encryptionKeyNameDescription: 'Specify name of the encryption key.',
    updateEncryptionKey: 'Update encryption key',
    verificationCodeTitle: 'Verification code',
    verificationCodeDescription: 'Verification code adds an extra layer of security to your account.',
    encryptionKeysUpdateTitle: 'Rotation period',
    next: 'Next',
    country: 'Country'
  },
  EncryptionKeyRotatePage: {
    rotate: 'Rotate',
    myCompany: 'My organization',
    environments: 'Environments',
    encryptionKeys: 'Encryption Keys',
    verification: 'Please verify the values',
    parameters: 'Parameters',
    encryptionKeyName: 'Name',
    encryptionKeyNameDescription: 'Specify name of the encryption key.',
    rotateEncryptionKey: 'Rotate encryption key',
    verificationCodeTitle: 'Verification code',
    verificationCodeDescription: 'Verification code adds an extra layer of security to your account.',
    encryptionKeysRotateTitle: 'Rotation period',
    encryptionKeysRotateDescription:
      'When you use the {{brandingTitle}}-managed encryption keys, you should consider that key rotation policies are applied automatically. You can define the appropriate key rotation period upon expiry of which a new version of the secret key is generated.',
    next: 'Next',
    country: 'Country'
  },
  UpdateEncryptionKeyForm: {
    name: 'Name',
    required: 'Required',
    rotationPeriod: 'Rotation period',
    rotationPeriodNotice: 'Rotation of encryption keys does not occur immediately and may take up to 24 hours. When you use the {{brandingTitle}}-managed encryption keys, you should consider that key rotation policies are applied automatically. You can define the appropriate key rotation period upon expiry of which a new version of the secret key is generated.',
    oneMonth: '1 month (30 days)',
    twoMonths: '2 months (60 days)',
    sixMonths: '6 months (180 days)',
    oneYear: '1 year (360 days)',
  },
  CreateEncryptionKeyForm: {
    createNewVersion: 'Add version',
    createNewEncryptionKey: 'Add encryption key',
    managementType: 'Key management type',
    name: 'Name',
    label: 'Name',
    keyType: 'Key type',
    rotationPeriod: 'Rotation period',
    oneMonth: '1 month (30 days)',
    twoMonths: '2 months (60 days)',
    sixMonths: '6 months (180 days)',
    oneYear: '1 year (360 days)',
    incountryKeyMgmt: '{{brandingTitle}} Managed',
    manualKeyMgmtAws: 'Manual: AWS',
    plainKey: 'Plain encryption key (base64-encoded)',
    region: 'AWS Region Code',
    encryptedKey: 'Encrypted key',
    customerManagedKey: 'Customer managed key (CMK) ARN',
    iamAccessKeyId: 'IAM access key ID',
    iamSecretKey: 'IAM secret key',
    notValidBase64: 'Not valid Base64 encoded key',
  },
  MembersPage: {
    email: 'Email',
    role: 'Role',
    updated: 'Updated',
    inviteNewMember: 'Invite user',
    membersAndInvitations: 'Users',
    owner: 'Owner',
    member: 'Member',
    product: 'Product',
    billing: 'Billing',
    account: 'Account',
    guest: 'Guest',
    inviteIsPending: 'Invite is pending...',
    changeRole: 'Change role',
    resetMemberPassword: 'Reset password',
    removeMember: 'Remove member',
    resendInvite: 'Resend invite',
    cancelInvite: 'Cancel invite',
    transferOwnership: 'Transfer ownership',
  },
  MemberRemovePage: {
    membersAndInvitations: 'Users',
    removeMember: 'Remove member',
    remove: 'Remove',
    areYouSure: 'Are you sure you want to remove a member from your organization?',
    thisActionWillRemove: 'This action will remove',
    member: 'member from your organization.',
    manageAccess: 'Manage access',
    pageDescription: '{{brandingTitle}} Portal lets you manage users within your organization and invite new users.',
    back: 'Back',
  },
  MemberResetPasswordPage: {
    membersAndInvitations: 'Users',
    resetMemberPassword: 'Reset member password',
    resetPassword: 'Reset password',
    areYouSureYouWantToReset: 'Do you really want to reset password of a member?',
    thisActionWillResetPasswordOf: 'This action will send a reset password request email to',
    member: 'member from your organization.',
    manageAccess: 'Manage access',
    pageDescription: '{{brandingTitle}} Portal lets you manage users within your organization and invite new users.',
    back: 'Back',
  },
  MemberChangeRolePage: {
    membersAndInvitations: 'Users',
    changeMemberRole: 'Change member role',
    roles: 'Roles',
    confirmNewRole: 'Confirm new role',
    verification: 'Please verify the values',
    parameters: 'Parameters',
    enterVerificationCode:
      'A verification code protects from an accident removal of an SDK. You can change parameters of verification codes on the Profile page.',
    managingRole:
      "By default, all the users' roles within your organization can be managed by the user with the Owner or Account Management role. All the newly registered users receive the Member role.",
    manageAccess: 'Manage access',
    pageDescription: '{{brandingTitle}} Portal lets you manage users within your organization and invite new users.',
    next: 'Next',
    update: 'Update',
  },
  ChangeMemberRoleForm: {
    role: 'Role',
    owner: 'Owner',
    member: 'Member',
    auditor: 'Configuration auditor',
    administrator: 'Configuration administrator',
    manager: 'Account manager',
    guest: 'Guest',
    changeMemberRole: 'Change member role',
    required: 'Required',
  },
  MemberTransferOwnership: {
    companySettings: 'Organization',
    parameters: 'Parameters',
    verification: 'Please verify the values',
    transfer: 'Transfer',
    transferOwnership: 'Transfer ownership',
    confirmTransfer: 'Confirm transfer',
    manageAccess: 'Manage access',
    pageDescription: '{{brandingTitle}} Portal lets you manage users within your organization and invite new users.',
    enterVerificationCode:
      'Please, check that you transfer ownership to a proper member and confirm this action with a verification code.',
    managingOwnershipTitle: 'Managing ownership',
    managingOwnershipDesc:
      '{{brandingTitle}} Portal allows only one owner per organization. The owner of the organization can transfer the ownership to another registered user with Configuration Administrator, Configuration Auditor or Account Manager role within the scope of the organization.',
    next: 'Next',
  },
  TransferMemberOwnershipForm: {
    transferOwnership: 'Transfer ownership',
    member: 'Member',
    password: 'Password',
    required: 'Required',
    role: 'Role',
  },
  InviteResendPage: {
    resendInvite: 'Resend invite',
    membersAndInvitations: 'Users',
    areYouSure: 'Do you really want to resend an invitation?',
    thisActionWillSendTo: 'This action will send an invitation email to',
    memberOfOrganization: 'member from your organization.',
    manageAccess: 'Manage access',
    pageDescription: '{{brandingTitle}} Portal lets you manage users within your organization and invite new users.',
    back: 'Back',
  },
  InviteCancelPage: {
    cancelInvite: 'Cancel invite',
    membersAndInvitations: 'Users',
    areYouSureYouWantToCancel: 'Do you really want to cancel an invitation?',
    thisActionWillCancelInviteSentTo: 'This action will cancel the invite that you have sent to',
    member: 'member earlier.',
    manageAccess: 'Manage access',
    pageDescription: '{{brandingTitle}} Portal lets you manage users within your organization and invite new users.',
    back: 'Back',
  },
  MemberInvitePage: {
    membersAndInvitations: 'Users',
    verification: 'Please verify the values',
    parameters: 'Parameters',
    invite: 'Invite',
    inviteNewMember: 'Invite user',
    invitationEmail: 'Invitation email',
    next: 'Next',
    confirmAndInvite: 'Confirm & invite',
    manageAccess: 'Manage access',
    pageDescription: '{{brandingTitle}} Portal lets you manage users within your organization and invite new users.',
    invitationDescription: 'Email of user to invite',
    enterVerificationCode:
      'A verification code protects from an accident removal of an SDK. You can change parameters of verification codes on the Profile page.',
  },
  InviteMemberForm: {
    email: 'Email',
    inviteNewMember: 'Invite user',
    back: 'Back',
    required: 'Required',
    alreadyInCompany: 'You are already a member of the organization',
  },
  ResidentFunctionsPage: {
    country: 'Country',
    myCompany: 'My organization',
    environments: 'Environments',
    createNewEnvironment: 'Add environment',
    services: 'Services',
    residentFunctions: 'Resident functions',
    newFunction: 'Add function',
    execute: 'Execute',
    configure: 'Configure',
    delete: 'Delete',
    name: 'Name',
    created: 'Created',
    updated: 'Updated',
    sdkSecret: 'Client secret',
    invalidClient: 'You have entered an invalid client secret',
    pageTitle: 'Working with resident functions',
    residentFunctionService: 'Resident function service',
    pageDescription:
      'A resident function is a function which allows you to perform remote and compliant operations on regulated data without its leaving the country of origin, so that you do not violate any local requirements for handling personal data.',
    viewLogs: 'View logs',
  },
  ResidentFunctionExecuteResults: {
    country: 'Country',
    residentFunctions: 'Resident functions',
    environments: 'Environments',
    services: 'Services',
    executeFunction: 'Execute function',
    result: 'Result',
    exitCode: 'Exit code',
    exitSignal: 'Exit signal',
    duration: 'Duration',
    invalidPayload: 'Invalid payload',
  },
  ResidentFunctionsControlForm: {
    country: 'Country',
    functionName: 'Function name',
    parameters: 'Parameters',
    countryCode: 'Country code',
    name: 'Name',
    value: 'Value',
    back: 'Back',
    publish: 'Publish',
    update: 'Update',
    required: 'Required',
    execute: 'Execute',
    addParameter: 'Add parameter',
    removeParameter: 'Remove parameter',
    nameCantBeModified: "The resident function's name cannot be modified",
    residentFunctionProductionAlert: 'This resident function will process production data.',
    updateBeforeExecute: 'You changed the function body. Please, update the function before executing it.',
  },
  ResidentFunctionConfigurePage: {
    country: 'Country',
    newFunction: 'Add function',
    publishFunction: 'Publish function',
    updateFunction: 'Update function',
    configureFunction: 'Configure function',
    publish: 'Publish',
    update: 'Update',
    environments: 'Environments',
    services: 'Services',
    residentFunctions: 'Resident functions',
    functionCreated: 'Function "{{name}}" is successfully created',
    functionUpdated: 'Function "{{name}}" is successfully updated',
    beautify: 'Beautify',
    invalidClient: 'You have entered an invalid client secret',
  },
  ResidentFunctionPublishPage: {
    country: 'Country',
    newFunction: 'Add function',
    publishFunction: 'Publish function',
    updateFunction: 'Update function',
    publish: 'Publish',
    update: 'Update',
    environments: 'Environments',
    services: 'Services',
    residentFunctions: 'Resident functions',
    functionCreated: 'Function "{{name}}" is successfully created',
    beautify: 'Beautify',
    invalidClient: 'You have entered an invalid Client Secret',
  },
  ResidentFunctionDeletePage: {
    country: 'Country',
    myCompany: 'My organization',
    deleteFunction: 'Delete function',
    services: 'Services',
    environments: 'Environments',
    verification: 'Please verify the values',
    parameters: 'Parameters',
    delete: 'Delete',
    residentFunctions: 'Resident functions',
    areYouSure: 'Are you sure you want to delete this function?',
    thisWillDelete: 'This will permanently delete the `{{name}}` function.',
    invalidClient: 'You have entered an invalid Client Secret',
  },
  MainMenu: {
    companySettings: 'Organization settings',
    subscription: 'Subscription',
    start: 'Start',
    settings: 'Settings',
    logout: 'Log out',
    usage: 'Usage',
    environments: 'Environments',
    inAppExtensions: 'In-app extensions',
    paymentVault: 'Payment vault',
    emailGateway: 'Email Gateway',
    htmlGateway: 'HTML Gateway',
    webFormGateway: 'Web Form Gateway',
    encryptionKeys: 'Encryption Keys',
    complianceAdvisor: 'Compliance advisor',
    support: 'Support',
    gettingStarted: 'Getting started',
    documentation: 'Documentation',
    portal: 'Portal',
    border: 'Border',
    salesforce: 'Salesforce',
    restApi: 'Rest API',
    pythonSdk: 'Python SDK',
    cSharpSdk: 'C# SDK',
    javaSdk: 'Java SDK',
    nodeJsSdk: 'Node.js SDK',
    contactSupport: 'Contact support',
    organization: 'Organization',
    users: 'Users',
    noReportingData1: 'No usage data is available. To view usage reports, please start by ',
    noReportingData2: 'creating records in the InCountry Platform.',
  },
  CopyToClipboardButton: {
    copyToClipboard: 'Copy to clipboard',
  },
  EnhancedTable: {
    rowsPerPage: 'Rows per page',
    prevPage: 'Previous page',
    nextPage: 'Next page',
    noDataToShow: 'No data to show',
  },
  MfaTotpPage: {
    twoFactorAuthentication: 'Two-Factor authentication settings',
    TwoFASettings: '2FA settings',
    profile: 'Profile',
    verification: 'Verification',
    recoveryCodes: 'Recovery codes',
    setupTotpApp: 'Setup TOTP app',
    cancel: 'Cancel',
    next: 'Next',
    back: 'Back',
    totp: 'TOTP',
    copyToClipboard: 'Copy to Clipboard',
    downloadCodes: 'Download codes',
    successfullyCopiedToClipboard: 'Successfully copied to clipboard',
    confirmWithCode: 'Confirm with code',
    confirmWithCodeDescription:
      'Before configuring the authentication app, please confirm this action with the code that we sent to your email.',
    recoveryCodesDescription:
      'Recovery codes are used to access your account if you cannot receive verification codes. Treat your recovery codes with the same level of attention as you would your password. Download or copy your recovery codes before proceeding with the two-factor authentication setup below.',
    myCompany: 'My organization',
  },
  MfaEmailPage: {
    back: 'Back',
    twoFactorAuthentication: 'Two-Factor authentication settings',
    email: 'Email',
    switchToEmail: 'Switch to email',
    profile: 'Profile',
    areYouSure: 'Do you really want to switch to email?',
    disableTotpAction: 'This action will switch you back to using email as a two-factor authentication (2FA) factor.',
    myCompany: 'My organization',
    mfaTitle: 'Two-factor authentication',
    mfaDescription:
      'Two-factor authentication is an electronic authentication method that grants access to a website or application to users only after successfully presenting two or more pieces of evidence to an authentication mechanism: knowledge, possession, and inherence.',
  },
  TotpBarcodeComponent: {
    totpApplicationCode: 'TOTP application code',
    enterThisTextCode: 'enter this text code',
    scanBarcodeWithYourApp: 'Scan this barcode with your app',
    recoveryCodes: 'Recovery codes',
    twoFactorAuthenticationSettings: 'Two-factor authentication settings',
    back: 'Back',
    confirm: 'Confirm',
    copyToClipboard: 'Copy to clipboard',
    successfullyCopiedToClipboard: 'Successfully copied to clipboard',
    switchedToOtp: 'Two-factor authentication successfully switched to authenticator app',
    invalidCode: 'Invalid Code',
    qrCodeDescription:
      "Scan the image with an authentication app on your smartphone.\nIf you can't use a QR scanner, enter `{{code}}` code instead.",
    qrCodeDescriptionAfter:
      'After scanning the QR code, the app will display a six-digit code that you need to enter below to enable this authentication method',
    cancel: 'Cancel',
  },
  TotpRecoveryCodes: {
    copyToClipboard: 'Copy to clipboard',
    downloadCodes: 'Download Codes',
    successfullyCopiedToClipboard: 'Successfully copied to clipboard',
    next: 'Next',
    cancel: 'Cancel',
  },
  IdleTimerComponent: {
    yourSessionIsExpiringIn: 'Your session is about to expire in',
    moveYourMousePointer: 'seconds. Move your mouse pointer to continue remain logged in.',
  },
  CreateCompanyForm: {
    organizationName: 'Organization name',
    country: 'Country',
    createCompany: 'Create organization',
    required: 'Required',
    emailNotifications: 'Email notifications',
    emailNotificationsSubTitle: 'Enable or disable notifications for alerts via email'
  },
  CreateCompanyExternalForm: {
    name: 'Name',
    country: 'Country',
    logout: 'Log out',
    required: 'This field is required',
    maxLength: 'The maximum length of a organization name is {{maxLength}} characters',
    create: 'Create',
  },
  CreateCompanyPage: {
    createCompany: 'Add organization',
    createNewCompany: 'You were excluded from your previous organization. To continue, please, create a new one.',
  },
  LoginPage: {
    dataResidency: 'Data Residency-as-a-Service (DRaaS)',
    supporting: 'supporting 90+ countries around the world',
    loginToPortal: 'LOG IN WITH E-MAIL ACCOUNT',
    welcome:
      'Welcome to the {{longBrandingTitle}} Portal! Here you can start your journey to the world of data residency and compliance. Create your free account or log in to the {{brandingTitle}} Portal to take advantage of our data residency services for your business.',
    welcomeDss:
      'Welcome to the {{longBrandingTitle}} Here you can start your journey to the world of data residency and compliance. Create your free account or log in to the {{brandingTitle}} Portal to take advantage of our data residency services for your business.',
    description:
      '{{longBrandingTitleWithAbbreviation}} provides a unique data residency-as-a-service platform that eases your compliance burden, letting you easily access new markets, regardless of data regulations, within tight time frames.',
    descriptionDCC:
      '{{longBrandingTitleDCC}} provides a unique data residency-as-a-service platform that eases your compliance burden, letting you easily access new markets, regardless of data regulations, within tight time frames.',
    defaultDescription: `InCountry's Data Residency-as-a-Service extends existing global applications with full data compliance in countries worldwide. Rather than operate a separate application in each country, InCountry's secure points-of-presence offer global applications deep edge services for compliant and isolated data storage, processing, and delivery.`,
    resetMyPassword: 'Reset password',
    createFreeAccount: 'Create free account',
    resendConfirmation: 'Resend confirmation',
    learnMoreAt: 'Learn more at {{brandingWebsite}}',
    loginWithSso: 'Log in with single sign-on (SSO)',
  },
  ChangeEmailForm: {
    email: 'Email',
    changeEmail: 'Change email',
    back: 'Back',
    errorSameEmail: 'You have entered the same email you already have',
    required: 'Required',
  },
  ChangeEmailPage: {
    change: 'Change',
    next: 'Next',
    profile: 'Profile',
    myCompany: 'My organization',
    changeEmail: 'Change email',
    verification: 'Please verify the values',
    parameters: 'Parameters',
    emailField: 'Email',
    checkEmailForInstructions: 'Please check your email for further instructions',
    emailFieldDescription:
      'You can modify the email address of your account created on the {{brandingTitle}} Portal. Check your mailbox for the letter with the subject Confirm Changing Account Email.',
  },
  ConfirmOldEmailPage: {
    changeEmailAddress: 'Change email address',
    weReceivedRequest: 'We received a request to change your account email address.',
    toContinue: 'To continue, click the button below.',
    thisRequestIsValid:
      'This request is valid for 1 hour. If you did not make this request, please disregard this email.',
    proceedEmailAddressChange: 'Proceed Email Address Change',
    checkYourEmail: 'Please, check your new email for confirmation.',
  },
  ConfirmNewEmailPage: {
    confirmNewEmailAddress: 'Confirm new email address',
    toContinue: 'To continue, click the button below.',
    tankYouForConfirming: 'Thank you for confirming your new email.',
    thisRequestIsValid:
      'This request is valid for 1 hour. If you did not make this request, please disregard this email.',
    youInitiatedTheChange:
      'You initiated the change of your email address on the {{brandingTitle}} Portal. We just need to verify your email address before assigning the new email address to your account.',
  },
  BorderConfigsPage: {
    myCompany: 'My organization',
    environments: 'Environments',
    services: 'Services',
    borderConfigs: 'Web Services Endpoints',
    borderConfigCreated: '"{{name}}" endpoint has been added',
    borderConfigUpdated: '"{{name}}" endpoint has been updated',
    name: 'Name',
    configurationId: 'Endpoint ID',
    createdAt: 'Created',
    edit: 'Edit',
    delete: 'Delete',
    view: 'View',
    newConfiguration: 'Add Endpoint',
    pageTitle: 'Endpoint',
    country: 'Country',
    webServices: 'Web Services',
    pageDescription:
      'Web Services Border is a component of {{brandingTitle}} that lets you integrate data residency services into your web application with minimal changes to your code. It resides between your customer-facing frontend and application backend, and provides on-the-fly redaction and unredaction of regulated or sensitive data transmitted during API requests.',
    exportToJSON: 'Export to JSON',
    importFromJSON: 'Import from JSON',
    viewLogs: 'View logs',
    cantEdit: 'This endpoint configuration cannot be modified as Salesforce service has been created in this country.',
    emptyStateText: 'Add a new Web Service Endpoint',
  },
  ImportBorderConfigPage: {
    myCompany: 'My organization',
    environments: 'Environments',
    services: 'Services',
    importBorderConfiguration: 'Import endpoint',
    borderConfigCreated: '"{{name}}" endpoint has been added',
    borderConfigUpdated: '"{{name}}" endpoint has been updated',
    pageTitle: 'Endpoint',
    country: 'Country',
    webServices: 'Web Services',
    pageDescription:
      'Web Services Border is a component of {{brandingTitle}} that lets you integrate data residency services into your web application with minimal changes to your code. It resides between your customer-facing frontend and application backend, and provides on-the-fly redaction and unredaction of regulated or sensitive data transmitted during API requests.',
  },
  EditBorderConfigPage: {
    myCompany: 'My organization',
    environments: 'Environments',
    services: 'Services',
    editBorderConfig: 'Edit endpoint',
    editBorderConfigName: 'Edit endpoint',
    borderConfigCreated: '"{{name}}" endpoint has been added',
    borderConfigUpdated: '"{{name}}" endpoint has been updated',
    pageTitle: 'Endpoint',
    country: 'Country',
    webServices: 'Web Services',
    pageDescription:
      'Web Services Border is a component of {{brandingTitle}} that lets you integrate data residency services into your web application with minimal changes to your code. It resides between your customer-facing frontend and application backend, and provides on-the-fly redaction and unredaction of regulated or sensitive data transmitted during API requests.',
  },
  CreateBorderConfigPage: {
    myCompany: 'My organization',
    environments: 'Environments',
    services: 'Services',
    createBorderConfig: 'Add Endpoint',
    createBorderConfigName: 'Create {{name}}',
    borderConfigCreated: '"{{name}}" endpoint has been added',
    borderConfigUpdated: '"{{name}}" endpoint has been updated',
    pageTitle: 'Endpoint configurator',
    country: 'Country',
    webServices: 'Web Services',
    pageDescription:
      'Web Services Border is a component of {{brandingTitle}} that lets you integrate data residency services into your web application with minimal changes to your code. It resides between your customer-facing frontend and application backend, and provides on-the-fly redaction and unredaction of regulated or sensitive data transmitted during API requests.',
  },
  BorderConfigForm: {
    author: 'Author',
    target: 'Target',
    borderConfigId: 'Endpoint ID',
    borderConfigName: 'Endpoint name',
    configurationVersion: 'Endpoint version',
    country: 'Country',
    parameters: 'Parameters',
    redactionRules: 'Redaction rules',
    unredactionRules: 'Unredaction rules',
    review: 'Review',
    verification: 'Please verify the values',
    verificationWarning:
      'Please create an empty endpoint and register its configuration identifier in the InCountry Data Residency for Salesforce package for the required form.',
    environmentId: 'Environment ID',
    configurationId: 'Endpoint ID',
    borderTargetEndpoint: 'Target endpoint',
    synchronizationEndpoint: 'Synchronization endpoint',
    pageTitle: 'Endpoint configurator',
    propertiesName: 'Endpoint name',
    propertiesUrl: 'Existing REST URL to redact/unredact data',
    properties: 'Properties',
    pageDescription:
      'Web Services Border is a component of {{brandingTitle}} that lets you integrate data residency services into your web application with minimal changes to your code. It resides between your customer-facing frontend and application backend, and provides on-the-fly redaction and unredaction of regulated or sensitive data transmitted during API requests.',
    cancel: 'Cancel',
    close: 'Close',
    back: 'Back',
    submit: 'Submit',
    next: 'Next',
    edit: 'Edit',
    create: 'Create',
    borderEndpointEonfigurationTitle: 'Web Service Endpoint configuration',
    navigation: 'Navigation',
    endpointStructure: 'Endpoint structure',
    redactions: 'Redaction rules',
    unredactions: 'Unredaction rules',
    createEndpoint: 'Add Endpoint',
    updateEndpoint: 'Update endpoint',
    field: 'Field',
  },
  BorderConfigParameters: {
    required: 'Required',
    mustBeValidUrl: 'Please provide a valid URL',
    mustBeValidHttpsUrl: 'Please provide a valid HTTPS URL',
    mustBe254: 'Maximum character limit: 254',
    borderConfigName: 'Endpoint name',
    country: 'Country',
    borderConfigId: 'Endpoint ID',
    author: 'Author',
    configurationVersion: 'Endpoint version',
    notValidTargetUrl: 'Target URL must use the HTTPS protocol',
    dataMasking: 'Data masking',
    geoIpDesc:
      'By default, Web Services Border automatically unredacts the data in the response body. You can forbid this for users requesting regulated data outside its country of origin. Regulated values will be masked except when requested from the country of origin. Once enabled, you can choose the appropriate masking algorithm within the settings of the unredaction rule.',
    corsEnabled: 'CORS settings',
    corsSettings: 'CORS settings',
    corsDescription:
      'Border simply proxies all authorization headers without making any modifications to them.  However, if you want to modify the default behavior, you can override the Access-Control-Allow-Origin header.',
    cors: 'Access-Control-Allow-Origin header',
    additionalSettings: 'Additional settings',
    name: 'Name',
    endpointName: 'Endpoint name',
    target: 'Existing top-level URL',
    targetDesc: 'Existing top-level URL of the web application',
    entityIdNotUnique: 'Entity IDs are not unique',
    entityIdUnique: 'Entity IDs are unique',
    globalEntityIdDescription:
      "To ensure proper entities identification and prevent collisions, it's crucial to have a unique identifier for each entity. Check this option only if your existing entities have unique IDs.",
    notValidPath:
      'Please use the URL without any URI path unless this is required. You can specify the URI path further when configuring redaction and unredaction rules.',
    developerMode: 'Developer mode',
    devModeHint: 'Please ensure that this mode is not enabled in environments storing production data. This mode enables IP spoofing of requests processed by Border, allowing you to provide a fake IP address instead of your actual IP address when testing data residency services in multiple countries.',
    developerModeDesc: "By activating this feature, the service will utilize a simulated developer IP address instead of real IP detection. It's important to note that this mode should not be enabled in environments where production data is stored.",
    entityIdChangeWarning: 'You are going to change "Entity IDs are unique" toggle state, this will lead to inaccessibility of your stored data, and Web Services Proxy will not unredact your data. Are you sure you want to proceed?',
  },
  BorderRedactionRules: {
    addRule: 'Add redaction rule',
    documentation: 'Documentation',
    requiresDtkAlert:
      'You selected one of the strategies that requires entry of the deterministic tokenization key (DTK). You can get this key in the InCountry Data Residency for Salesforce package after enabling the corresponding flag in the package settings. Please copy and paste the generated value into the field below:',
    dtkInputLabel: 'Deterministic tokenization key',
    redactionRuleInit: 'Add redaction rule',
    dtkLengthRequirement: 'Deterministic tokenization key must include 97 characters',
    updateRulePayload: 'Update request and response JSON',
  },
  BorderRedactionRule: {
    path: 'Path',
    required: 'Required',
    method: 'Method',
    deleteRule: 'Delete rule',
    operationMode: 'Operation mode',
    processing: 'Processing',
    processingAndStorage: 'Processing & Storage',
    resolveValidationErrors: 'Please, resolve all validation errors before collapsing this rule',
    type: 'Type',
    'border-search': 'Search',
    default: 'CRUD operations',
    redactionRule: 'Redaction rule',
    searchRule: 'Search endpoint',
    advancedSettings: 'Advanced settings',
    cloneRule: 'Duplicate rule',
    updateRequest: 'Update request and response JSON',
    validPath: 'Must be a valid URL path',
    ruleDuplicate: 'Seems like this rule duplicates',
    [`${BorderPlugins.default}Plugin`]: 'Default rule',
    [`${BorderPlugins.extended}Plugin`]: 'Extended rule',
    [`${BorderPlugins.borderSearch}Plugin`]: 'Search rule',
    [`${BorderPlugins.salesforce}Plugin`]: 'Salesforce Cross-Border',
  },
  BorderRedactionRuleSearchPlugin: {
    searchSettings: 'Search settings',
    authType: 'Authentication type',
    authEndpoint: 'Authentication endpoint',
    idsFilterEndpoint: 'Record filtration endpoint',
    searchEndpoint: 'Record search endpoint',
    criteriaMapping: 'Mappings for search request criteria',
    in: 'Examine in',
    queryOffsetParam: 'Offset parameter name',
    queryLimitParam: 'Pagination limit parameter name',
    defaultSearchLimit: 'Default pagination limit',
    endpointType: 'Endpoint type',
    records: 'Filter',
    search: 'Search',
    body: 'Request body',
    request: 'Request',
    schemaTable: 'Schema Table',
  },
  BorderRedactionRuleSearchPluginCriteria: {
    addCriterion: 'Add criterion',
    deleteCriteria: 'Delete criterion',
    path: 'Path',
    searchableKey: 'Indexed field',
    search_keys: 'Search in all keys',
  },
  BorderRedactionRuleCollection: {
    collection: 'Collection',
    collectionName: 'Collection name',
    required: 'Required',
    entityIdPath: 'Entity ID path',
    entityErrorCorrectionFieldPath: 'Entity error correction field path',
    strategies: 'Strategies',
    searchable: 'Searchable',
    globalEntityId: 'Global entity ID',
    nonUniquePrefix: 'Prefix to add to non-unique ID',
    pathUniqueId: 'Path to unique ID',
    pathNonUniqueId: 'Path to non-unique ID',
    tokenizedField: 'Randomly-tokenized field',
    uniqueSelectedDesc:
      'In the Properties section, you indicated that your entities have unique IDs. Please provide the path to the unique ID of this entity.',
    notUniqueSelectedDesc:
      'In the Properties section, you indicated that your entities do not have unique IDs. Please specify a unique prefix for this entity and the path to the non-unique ID.',
    notRandomStrategySelected:
      'You selected the algorithm that produces non-random redacted values. It is recommended to select the alphaNumeric, alphaNumericLowerCase, alphaPrepended, email, or dateISO algorithm..',
    entityIdPathHelper: 'Please ensure that the regular expression matches the path of your request.',
    errorCorrectionFieldHelper:
      'Enter the path to the record identifier that is returned within the response to this request.',
    collectionNameHelper:
      'Please give a meaningful and unique name to the entity that will allow the endpoint to correctly differentiate between entities even when they have the same identifier',
    stepSalesforceTitle: 'Step {{step}}: Salesforce Cross-Border parameters',
    stepSalesforceDesc: 'To configure Salesforce Cross-Border, please, provide the following parameters. To learn more about Salesforce Cross-Border, follow the',
    selectSchemaModel: 'Step {{step}}: Schema',
    selectSchemaModelDesc:
      '{{brandingTitle}} allows you to define schema of your records for more convenient execution of requests against regulated values. You can find more details about this feature in the',
    stepRedactionTitle: 'Step {{step}}: Fields to redact',
    stepRedactionDesc:
      '{{brandingTitle}} requires the selection of an algorithm for each field you want to redact in the request payload (JSON). Please select the appropriate algorithm for each redacted field. You can find the details on each algorithm in the',
    documentation: 'documentation',
    stepEntityTitle: 'Step {{step}}: Entity identification',
    stepEntityTitleDelete: 'Step {{step}}: Entity identification',
    notUniqueSelectedDeleteDesc:
      'In the Properties section, you indicated that your entities do not have unique IDs. Please specify a unique prefix for this entity.',
    allOrNoneFlagPath: 'allOrNone parameter',
    recordsPath: 'Records path',
    updateByExternalIdFlag: 'Update by External ID',
    externalIdPath: 'External ID path',
    searchPath: 'Search path',
    stepDlpTitle: 'Step {{step}}: Data Loss Prevention (DLP) excluded paths',
    stepDlpDesc: 'By default, {{brandingTitle}} Border automatically detects and redacts potential personally identifiable information (PII) data in the payload when it crosses borders. If you wish to exempt specific paths from this redaction, please utilize the form provided below.',
    schemaTable: 'Schema Table',
    stepQueryStrategy: 'Step {{step}}: Query parameters to redact',
    stepQueryStrategyDesc: 'If you have query parameters that contain data to be redacted, specify them here.',
  },
  RenderSearchable: {
    addSearchable: 'Add searchable',
    searchable: 'Searchable',
  },
  BorderRedactionRuleStrategies: {
    redactField: 'Add redacted field',
  },
  RenderStrategies: {
    redactField: 'Add redacted field',
    errorCorrectionField: 'Error correction field',
    strategies: 'Strategies',
    unredactField: 'Add unredacted field',
  },
  BorderRedactionRuleStrategy: {
    path: 'Path',
    strategy: 'Algorithm',
    deleteStrategy: 'Delete algorithm',
    value: 'Value',
    required: 'Required',
    length: 'Length',
    searchableKey: 'Indexed field',
    aggregated: 'Aggregated',
    searchable: 'Searchable',
    notSearchable: 'Not searchable',
    dtkLengthAlert:
      'If you use the InCountry Data Residency for the Salesforce package, please ensure that the tokenization functions and the length value are identical between this Border endpoint and Salesforce package configuration',
    lengthAlert:
      'To preserve the consistency of outputted redacted values, please consider that the InCountry Data Residency for Salesforce package always uses the default length of 64 characters when using this redaction algorithm. Changing this value may result in the inconsistency of redacted values between different components of InCountry Data Residency-as-a-Service.',
    pathAlreadyAdded: 'The same path is used multiple times in the redaction rule',
  },
  BorderUnredactionRuleCollection: {
    collection: 'Collection',
    collectionName: 'Collection name',
    required: 'Required',
    entityIdPath: 'Entity ID path',
    entityErrorCorrectionFieldPath: 'Entity error correction field path',
    strategies: 'Strategies',
    deleteCollection: 'Delete collection',
    globalEntityId: 'Global entity ID',
    uniqueSelectedDesc:
      'In the Properties section, you indicated that your entities have unique IDs. Please provide the path to the unique ID of this entity.',
    notUniqueSelectedDesc:
      'In the Properties section, you indicated that your entities do not have unique IDs. Please select the prefix for this entity that you previously specified in the redaction rules, and then provide the path to the non-unique ID.',
    step1: 'Step 2: Entity identification',
    step2: 'Step 3: Fields unredaction',
    step2Desc: 'If the response format differs from the previously specified paths, create a mapping in this section.',
    entityPrefix: 'Entity prefix',
    pathUniqueId: 'Path to unique ID',
    pathNonUniqueId: 'Path to non-unique ID',
    tokenizedField: 'Randomly-tokenized field',
    schemaTable: 'Schema Table',
    selectSchemaModel: 'Step {{step}}: Schema',
    selectSchemaModelDesc:
      '{{brandingTitle}} allows you to define schema of your records for more convenient execution of requests against regulated values. You can find more details about this feature in the',
  },
  BorderUnredactionRules: {
    addRule: 'Add unredaction rule',
    documentation: 'Documentation',
    unredactionRuleInit: 'Add unredaction rule',
    updateRulePayload: 'Update request and response JSON'
  },
  BorderUnredactionRule: {
    addCollection: 'Add collection',
    deleteRule: 'Delete rule',
    path: 'Path',
    method: 'Method',
    required: 'Required',
    processing: 'Processing',
    processingAndStorage: 'Processing & Storage',
    resolveValidationErrors: 'Please, resolve all validation errors before collapsing this rule',
    multipleEntitiesTitle: 'Does the endpoint return multiple entities?',
    multipleEntitiesDesc:
      'In certain cases, an endpoint may return more than one entity. For instance, the "/posts" endpoint could potentially return both posts and authors, both of which handle regulated or sensitive values in the redacted form. If this is applicable to your situation, you will need to explicitly specify each entity and provide instructions on how to unredact them.',
    cloneRule: 'Duplicate rule',
    validPath: 'Must be a valid URL path',
    updateResponse: 'Update response',
  },
  BorderUnredactionRuleStrategy: {
    path: 'Path',
    originalPath: 'Original path',
    deleteStrategy: 'Delete algorithm',
    required: 'Required',
    maskingAfterNCharacters: 'Masking after N characters',
    maskingCharacter: 'Masking character',
    maskingLength: 'Masking length',
    valueDelimiter: 'Value delimiter',
    default: 'default',
    masking: 'data masking',
    fixed: 'fixed',
    value: 'Value',
    type: 'Type',
    strategy: 'Strategy',
    ' ': 'Space',
    _: 'Underscore (_)',
    '/': 'Slash (/)',
    '-': 'Hyphen (-)',
    algorithm: 'Algorithm',
    pathAlreadyAdded: 'The same path is used multiple times in the unredaction rule'
  },
  BorderConfigSidebar: {
    properties: 'Properties',
    redactions: 'Redaction rules',
    unredactions: 'Unredaction rules',
    createEndpoint: 'Add Endpoint',
    updateEndpoint: 'Update endpoint',
    cancel: 'Cancel',
    back: 'Back',
    field: 'Field',
  },
  BorderRuleInitForm: {
    method: 'HTTP request method',
    path: 'Path relative to Existing REST URL',
    requestJson: 'Request payload (JSON)',
    responseJson: 'Response body (JSON)',
    close: 'Close',
    createRule: 'Add rule',
    updateRule: 'Update rule',
    useExample: 'Use an example',
    enableSearchRule: 'This is a search request',
    description1:
      'Provide an HTTP method and a relative path to the existing REST URL to get entity for {{type}}.',
    description2: "Optionally, provide the request and response JSON data so that we can suggest specific fields that could be {{type}} (this data won't be stored).",
    validPath: 'Must be a valid URL path',
    'border-search': 'Search request',
    default: 'Default rule',
    'sf-multiple-upsert': 'Salesforce Cross-Border',
    salesforceDesc: 'Define the anonymization algorithm for regulated fields and apply triggers to automatically transport the data cross-border to a global Salesforce org using InCountry Border.',
    searchDesc: 'Search data by regulated fields stored in InCountry Vault, as well as by non-regulated fields stored in the application database.',
    defaultDesc: 'Handle regulated data based on rules that describe the structure of request payloads and response bodies and regulate fields that contain sensitive values.',
    type: 'Type',
    extendedDesc: 'The extended redaction rule facilitates batch creation or updating of records (DLP is not included).',
    'bulk-create-update': 'Extended rule',

  },
  UserRoleChip: {
    owner: 'Owner',
    member: 'Member',
    auditor: 'Configuration auditor',
    configurationAdministrator: 'Configuration administrator',
    accountManager: 'Account manager',
    guest: 'Guest',
    loading: 'Loading...',
  },
  FooterMenu: {
    incountryAddress: '{{brandingWebsite}}',
    contactSupport: 'Contact support',
    phoneSupport: 'Call support',
    documentation: 'Documentation',
    privacyPolicy: 'Privacy Policy',
    terms: 'Terms & Conditions',
  },
  SubscriptionPlan: {
    fetching: 'Fetching',
  },
  Page404: {
    title: 'Error 404 - Page not found',
    subTitle: 'Sorry, but the page you were looking for could not be found.',
    youCanReturn: 'You can return to our',
    gettingStartedPage: 'Getting Started Page',
    or: ', or',
    contactUs: 'Contact us',
    ifYouCantFind: "if you can't find what you're looking for.",
  },
  Page500: {
    title: 'Internal server error',
    subTitle: 'Something went wrong',
    serverEncounteredError:
      'The server encountered an internal error or misconfiguration and was unable to complete your request.',
    contactUs: 'Contact us',
    home: 'Home',
  },
  ShowCredentialsComponent: {
    copyToClipboard: 'Copy to clipboard',
    successfullyCopiedToClipboard: 'Successfully copied to clipboard',
    downloadText: 'Download',
    copy: 'Copy',
    successfullyCopied: 'Successfully copied',
  },
  PaymentVaultsPage: {
    showCode: 'Show code',
    delete: 'Delete',
    paymentVaults: 'Payment vaults',
    newPaymentGateway: 'Add payment vault',
    pgPageTitle: 'What is Payment Vault?',
    pgPageDescription:
      'Payment Vault is a technology solution that enables the secure handling of payment card data, storing it in the isolated data store, and providing to payment providers once the cardholder performs a payment or subscribes to a service.  It uses data tokenization to communicate payment card data between the cardholder and the target payment provider and secures it from potential threats during transmission. Payment Vault allows you to be PCI DSS compliant without implementing any additional functionality on your side.',
  },
  StepperComponent: {
    close: 'Close',
    cancel: 'Cancel',
    back: 'Back',
    enterTotpCode: 'Enter the code from the authentication app',
    enterEmailCode: 'Enter the code from your email',
    previousCodeIsValid: 'Previously entered verification code is still valid',
    leaveConfirmText: 'You are about to leave the page with unsaved modifications. Do you want to leave this page?',
    leaveConfirmTitle: 'Are you sure you want to leave?',
  },
  CreatePaymentVaultForm: {
    environmentId: 'Environment ID',
    newPaymentGateway: 'Add payment vault',
    country: 'Country',
    required: 'Required',
    parameters: 'Parameters',
    paymentProvider: 'Payment provider',
    environmentSetup: 'Environment setup',
    review: 'Review',
    embedCode: 'Embed code',
    create: 'Create',
    pgStep1Description:
      'Configuration of a Payment Vault is quite simple. Select the provider that will process the payment and the country where the payment card data will be saved. Once this is complete, please proceed to the next step.',
    previous: 'Previous',
    environment: 'Environment',
    SDK: 'SDK',
    name: 'Name',
    requestType: 'Request type',
    back: 'Back',
    mt: 'MULTI-TENANT',
    st: 'SINGLE-TENANT',
    tenant: 'Tenant',
    applicationPaymentEndpoint: 'Application endpoint for payment cards data',
    pgStep2Title1: 'Setup of {{brandingTitle}} infrastructure',
    pgStep2Description1:
      'Once you have defined the country and the payment provider, you need to configure the environment for storing data and the SDK whose credentials will be used to authorize data requests. You can either select the existing environment and SDK or let {{brandingTitle}} Portal create them from scratch.',
    pgStep2Title2: 'Environment',
    pgStep2Description2:
      'The environment defines the operational context for storing regulated data on the {{brandingTitle}} platform. You can use environments to regulate access to stored data.',
    pgStep2Title3: 'SDK',
    pgStep2Description3:
      'The SDK is a set of credentials you can use to access regulated data stored within a specific environment. An SDK includes a Client ID and Secret that are further used to acquire an access token.',
    pgStep3Title1: 'Payment vault setup',
    pgStep3Description1:
      'Once you have defined the environment and the SDK, give a name to the Payment Vault service. In addition, please register your application’s endpoint to process payment data.',
    pgStep3Title2: 'Name',
    pgStep3Description2:
      'Give some meaningful name to the Payment Vault service you set up so you can easily understand what it means and why it was created.',
    pgStep3Title3: 'Application payment endpoint',
    pgStep3Description3: 'Enter the path to your application endpoint which payment card data is submitted to.',
    pgStep4Title1: 'Review configuration of payment vault',
    pgStep4Description1:
      'Before you save your Payment Vault’s configuration, please review it and ensure that everything is correct. First, ensure that you selected the right country and payment provider to work with. Check that payment card data will be saved into the correct environment on the {{brandingTitle}} platform. The last thing to check is the application payment endpoint where payment data is communicated. Once you have verified all of the configuration, click Save.',
    paymentDataEndpoint: 'Payment data endpoint',
    paymentGatewayName: 'Payment vault name',
    configurationReview: 'Configuration review',
    enterTotpCode: 'Enter TOTP or recovery code',
    enterEmailCode: 'Enter the code from your email',
    useVerificationCode: 'Use verification code',
    verificationCode: 'Verification code',
    confirmationNotice: 'Please check entered information one more time before continuing.',
    provider: 'Provider',
    endpoint: 'Endpoint',
    downloadText: 'Download as text',
    paymentGatewayCreated: 'Payment vault is created',
    paymentGatewayCreatedSubtitle:
      'Your service with Payment Vault has been created successfully and now you can use it to handle your customers’ payment card data.',
    copyWarning:
      'Copy or download the automatically generated data now, as you will not be able to view this data again.',
    embedService: 'Embed the service code of the payment vault into your web application within its head section.',
    embedCodeCopied: 'Embed code is copied',
    environmentIdIsCopied: 'Environment ID copied successfully',
    embedCodeTitle: 'Show example of payment vault embed code',
    embedCodeSubtitle:
      'The following is a sample script that you embed within your web application to handle payment card data. It includes the functions to handle payment card data and reflects the configuration you defined in previous steps.',
    pgDocumentation: 'Payment elements documentation',
    providerIsCopied: 'Provider copied successfully',
    providerNameIsCopied: 'Provider Name copied successfully',
    countryIsCopied: 'Country name copied successfully',
    endpointIsCopied: 'Endpoint copied successfully',
    paymentGateway: 'payment vault',
    embedSnippet: 'Embed Snippet',
    paymentGatewayEmbedSnippet: 'Payment vault embed snippet',
    showCode: 'Show code',
    createNewEnvironment: 'Add environment',
    save: 'Save',
    paymentGatewayEmailRequest:
      'Payment Vault operates in the experimental mode with the limited support of payment providers and countries. Help us prioritize our roadmap with payment providers and countries you want to have',
    paymentGatewayDropEmail: 'by dropping an email to us.',
    paymentGatewayCannotFindProvider: 'Cannot find your payment provider?',
    paymentGatewayCannotFindCountry: 'Cannot find the necessary country?',
    letUsKnow: 'Let us know',
    iframeSource: 'IFrame source',
    iframeSourceCopied: 'IFrame Source copied successfully',
    iframe: 'IFrame',
    close: 'Close',
    next: 'Next',
    cancel: 'Cancel',
    environments: 'Environments',
  },
  PaymentVaultDeletePage: {
    deleteService: 'Delete service',
    areYouSureDeletePaymentGateway: 'Are you sure you want to delete payment vault?',
    thisWillDelete: 'This will permanently delete Payment ({{code}}) service. This action cannot be undone.',
    paymentVaults: 'Payment vaults',
    delete: 'Delete payment vault',
    areYouSure: 'Are you sure you want to delete this payment vault?',
    environments: 'Environments',
    services: 'Services',
    paymentVault: 'Payment vault',
    country: 'Country',
  },
  PaymentVaultEmbedPage: {
    embedService: 'Embed the service code of the Payment Vault into your web application within its head section.',
    embedCodeCopied: 'Embed code is copied',
    embedCodeTitle: 'Embed code of Payment Vault',
    embedCodeSubtitle:
      'The following is a sample script that you embed within your web application to handle payment card data. It includes the functions to handle payment card data and reflects the configuration you defined in previous steps.',
    embedSnippet: 'Embed snippet',
    paymentGatewayEmbedSnippet: 'Payment vault embed snippet',
    paymentVaults: 'Payment vaults',
    iframeSource: 'IFrame source',
    iframeSourceCopied: 'IFrame source copied successfully',
    embedCode: 'Embed code',
    close: 'Close',
    environments: 'Environments',
    services: 'Services',
    paymentVault: 'Payment vault',
    embed: 'Embed payment vault snippet',
    country: 'Country',
  },
  ShowBorderServiceCredentials: {
    environmentId: 'Environment ID',
    proxyAddress: 'Proxy address',
    environmentIdIsCopied: 'Environment ID copied successfully',
    copy: 'Copy',
    makeSureToCopy:
      'Please copy this information now.\nYou will no longer be able to see it again after closing this page! Do you really want to continue?',
    sdkId: 'Client ID',
    sdkSecret: 'Client secret',
  },
  BorderConfigReview: {
    reviewAndConfirm: 'Endpoint review and confirmation',
  },
  EmailGatewaysPage: {
    name: 'Name',
    smtp: 'SMTP',
    environment: 'Environment',
    createdAt: 'Created',
    emailGateways: 'Email gateways',
    newEmailGateway: 'Add email gateway',
    details: 'Details',
    delete: 'Delete',
    edit: 'Edit',
    egPageTitle: 'What is Email Gateway?',
    egPageDescription:
      'Email Gateway is a technology solution that enables the secure swapping of value placeholders with the clear-text values of regulated data in emails. It is placed between the SDK application located outside the country of origin and the SMTP server located in the country of origin. Email Gateway captures outbound emails with regulated data placeholders, identifies them according to pre-defined patterns, and replaces these placeholders with clear-text data, such as names and email addresses. Once unredacted, emails are sent via a direct SMTP connection to the customer’s SMTP server that further delivers this email to the target recipient.',
  },
  EmailGatewayEmbedPage: {
    emailGateway: 'Email gateway',
    emailGateways: 'Email gateways',
    emailGatewayDetails: 'Email gateway details',
    emailGatewayDetailsDesc: 'Use this address for your service.',
    embedEmailService: 'Use service SMTP address to send you email with Email Gateway.',
    close: 'Close',
    environments: 'Environments',
    country: 'Country',
  },
  CreateEmailGatewayForm: {
    environments: 'Environments',
    newEmailGateway: 'Add email gateway',
    editEmailGateway: 'Edit email gateway',
    country: 'Country',
    emailGateway: 'Email gateway',
    environmentSetup: 'Environment setup',
    name: 'Name',
    review: 'Review',
    results: 'Results',
    back: 'Back',
    create: 'Create',
    next: 'Next',
    cancel: 'Cancel',
    close: 'Close',
    required: 'Required',
    environment: 'Environment',
    environmentId: 'Environment ID',
    sdkId: 'Client ID',
    sdkSecret: 'Client Secret',
    createNewEnvironment: 'Add environment',
    emailGatewayName: 'Email gateway name',
    embedEmailService: 'Use this address for your Email Gateway service',
    smtpServer: 'SMTP Server',
    port: 'Port',
    username: 'Username',
    password: 'Password',
    mt: 'MULTI-TENANT',
    smtpSettings: 'SMTP Settings',
    emailGatewayLogin: 'Email gateway login',
    emailGatewayPassword: 'Email gateway password',
    configurationReview: 'Configuration review',
    emailGatewayCreated: 'Email gateway created',
    emailNotValid: 'Email not valid',
    general: 'General',
    emailHandling: 'Email handling',
    outboundEmails: 'Outbound emails',
    inboundEmails: 'Inbound emails',
    imapServerName: 'IMAP server name',
    imapServer: 'IMAP server',
    senderEmail: 'Sender email',
    serviceMailAddress: 'Service mail address',
    applyImapSettingsToOutboundEmails: 'Apply SMTP settings to outbound emails',
    dtkInputLabel: 'Deterministic tokenization key',
    st: 'SINGLE-TENANT',
    emailGatewayCreatedSubtitle:
      'Your service with Email Gateway has been created successfully and now you can use it to process outbound emails with regulated data.',
    copyWarning:
      'Copy or download the automatically generated data now, as you will not be able to view this data again.',
    egStep1Title1: '',
    egStep1Description1:
      'Configuring the Email Gateway is quite simple. Simply enter the SMTP server address and the SMTP port for sending emails in addition to providing the necessary credentials to connect to it.',
    egStep1Title2: 'SMTP Server',
    egStep1Description2:
      'An SMTP (Simple Mail Transfer Protocol) server is an application that powers the protocol your email client uses when sending, receiving, and/or relaying outgoing messages. An SMTP server has an address that can be set by the mail  or application that you are using and is generally formatted as smtp.serveraddress.com.',
    egStep1Title3: 'Port',
    egStep1Description3:
      'This is a port that an SMTP Server uses for sending emails. Port 465 is used for implicit SSL/TLS communication, and port 587 for explicit SSL/TLS communication.',
    egStep1Title4: 'Username/password',
    egStep1Description4: 'The credentials to connect to and authorize the SMTP server to send emails.',
    egStep2Title1: 'Setup of {{brandingTitle}} infrastructure',
    egStep2Description1:
      'Once you have configured the SMTP server, you need to configure the environment for storing regulated data and the SDK whose credentials will be used to authorize data requests for email processing. You can either select an existing environment and SDK or let {{brandingTitle}} Portal create them from scratch.',
    egStep2Title2: 'Environment',
    egStep2Description2:
      'The environment defines the operational context for storing regulated data on the {{brandingTitle}} platform. You can use environments to regulate access to stored data.',
    egStep3Title: 'Review configuration of Email Gateway',
    egStep3Description:
      'Before you save your Email Gateway’s configuration, please review it and verify that everything is correct. Ensure that you have specified the correct configuration and credentials to connect to your SMTP server. Once this is done, enter the verification code and click Save.',
    dtkLengthRequirement: 'Deterministic tokenization key must include 97 characters',
    emailGatewayHost: 'Email gateway host',
    emailGatewayPort: 'Email gateway port',
  },
  EmailGatewayDeletePage: {
    deleteEmailGateway: 'Delete email gateway',
    emailGateways: 'Email gateways',
    delete: 'Delete',
    environments: 'Environments',
    country: 'Country',
    deleteService: 'Delete service',
    thisWillDelete:
      'This will permanently delete Email service. This action cannot be undone.',
  },
  SsoPage: {
    enterOrganizationId: 'Please enter your Organization ID to log in with custom SSO',
    dataResidency: 'Data Residency-as-a-Service',
    supporting: 'supporting 90+ countries around the world',
    defaultDescription: `InCountry's Data Residency-as-a-Service extends existing global applications with full data compliance in countries worldwide. Rather than operate a separate application in each country, InCountry's secure points-of-presence offer global applications deep edge services for compliant and isolated data storage, processing, and delivery.`,
    description:
      '{{longBrandingTitleWithAbbreviation}} provides a unique data residency-as-a-service platform that eases your compliance burden, letting you easily access new markets, regardless of data regulations, within tight time frames.',
    descriptionDCC:
      '{{longBrandingTitleDCC}} provides a unique data residency-as-a-service platform that eases your compliance burden, letting you easily access new markets, regardless of data regulations, within tight time frames.',
    learnMoreAt: 'Learn more at {{brandingWebsite}}',
    login: 'Log in',
    dontKnowOrgId: 'Don’t know your organization ID?',
    organizationId: 'Organization ID',
    back: 'Back',
  },
  DashboardViewToggle: {
    mapView: 'Map view',
    tableView: 'Table view',
  },
  CopyToClipboardWrapper: {
    successfullyCopiedToClipboard: 'Successfully copied to clipboard',
    copyToClipboard: 'Copy to clipboard',
  },
  DeleteEntity: {
    delete: 'Delete',
    back: 'Back',
  },
  CustomFormValidation: {
    required: 'This field is required',
    minLength: 'This field should contain at least {{ minLength }} characters',
    maxLength: 'This field should not contain more than {{ maxLength }} characters',
    max: 'Maximum allowed value is {{ max }}',
    min: 'Minimum allowed value is {{ min }}',
    unsafeXss: 'This string is unsafe and cannot be used as value',
    nonBlank: "This field can't be blank or contain prohibited character",
    errorPassphrase:
      'Your passphrase should have at least one uppercase letter, at least one digit, and at least one special character',
  },
  SalesforceServiceCertificatePage: {
    environments: 'Environments',
    services: 'Services',
    service: 'Service',
    uploadCertificate: 'Upload certificate',
    selectCertificate: 'Select certificate',
    replaceCertificate: 'Replace certificate',
    upload: 'Upload',
    verification: 'Please verify the values',
    certificate: 'Certificate',
    errorMaximumFilesize: 'Your file have reached maximum allowed size limit with {{filesize}} of {{limit}}',
    fileIsNotSelected: 'File is not selected',
    fileIsNotProper: 'File is not a proper Certificate',
    successfullyReplaced: 'Successfully replaced',
    successfullyUploaded: 'Successfully uploaded',
    certificateDescription:
      'Select the certificate you generated in Salesforce for communication of regulated data with the {{brandingTitle}} platform',
    certificateConfirmDescription:
      'Confirm the upload the certificate you generated in Salesforce for communication of regulated data with the {{brandingTitle}} platform',
    next: 'Next',
    salesforce: 'Salesforce',
  },
  DeleteBorderConfigPage: {
    myCompany: 'My organization',
    environments: 'Environments',
    delete: 'Delete',
    verification: 'Please verify the values',
    deleteBorderConfig: 'Delete endpoint',
    parameters: 'Parameters',
    areYouSure: 'Do you really want to delete this Endpoint',
    country: 'Country',
    thisWillDelete:
      'This will permanently delete endpoint in {{name}}. This action cannot be undone.',
    services: 'Services',
    webServices: 'Web Services',
  },
  ViewBorderConfigPage: {
    environments: 'Environments',
    services: 'Services',
    borderConfig: 'Endpoint',
    viewBorderConfig: 'View endpoint',
    environmentId: 'Environment ID',
    configurationId: 'Configuration ID',
    borderTargetEndpoint: 'Target endpoint',
    synchronizationEndpoint: 'Synchronization endpoint',
    myCompany: 'My organization',
    close: 'Close',
    country: 'Country',
    webServices: 'Web Services',
  },
  DateDisplayComponent: {
    localTime: 'Local time',
  },
  SessionMaintainingDialog: {
    sessionIsAboutToExpire: 'Your session is about to expire',
    youWillBeLoggedOut: "You'll be logged out in {{seconds}} seconds.",
    staySignedIn: 'Do you want to stay signed in?',
    keepMeSignedIn: 'Yes, keep me signed in',
    logout: 'Logout',
  },
  FileDropzone: {
    dragAndDropTitle: 'Drag and drop here',
    or: 'OR',
    browseFiles: 'Browse files',
    maxFileSize: 'Max file size is {{max}}',
    cancel: 'Cancel',
    next: 'Next',
  },
  EnvironmentAliasesPage: {
    keyAliasMapping: 'Schema',
    environments: 'Environments',
    noAliasesCreated: 'No schema created for this environment',
    key: 'Key',
    alias: 'Field',
    edit: 'Edit',
    delete: 'Delete',
    aliasesAccessInfo: 'Learn how to access your tables in the ',
  },
  EnvironmentAliasesDeletePage: {
    keyAliasMapping: 'Schema',
    environments: 'Environments',
    deleteAliases: 'Delete schema',
    areYouSure: 'Do you really want to delete this schema?',
    thisWillDelete: 'This will delete the field reference for the `{{name}}` environment. Deleting fields will not affect the records in the database. The records will still be accessible via `{{endpoint}}` endpoints. To delete data, write a script.',
  },
  AliasesForm: {
    modelName: 'Table name',
    removeModel: 'Remove table',
    addModel: 'Add new table',
    aliasesNote: "Please be thoughtful about setting up your data schema. Changing the schema does not automatically mirgrate data and you will have to manually write scripts to migrate data.",
    update: 'Update schema',
    delete: 'Delete schema',
  },
  AliasesSubForm: {
    aliasNotValid: 'Only alphanumeric, hyphen and underscore characters are allowed',
    aliasDuplication: 'Only unique schema names are allowed',
    alias: 'Field',
    key: 'Key',
    addAlias: 'Add new field',
    removeAlias: 'Remove field',
    dataType: 'Type',
    fieldType: 'Encryption',
    fieldName: 'Name',
    [SchemaDataTypeEnum.string]: 'String',
    [SchemaDataTypeEnum.integer]: 'Integer',
    [SchemaDataTypeEnum.decimal]: 'Decimal',
    [SchemaDataTypeEnum.boolean]: 'Boolean',
    [SchemaDataTypeEnum.date]: 'Date',
    [SchemaDataTypeEnum.time]: 'Time',
    [SchemaDataTypeEnum.datetime]: 'Date&Time',
    [SchemaFieldTypeEnum.indexed]: 'Unencrypted flexible search',
    [SchemaFieldTypeEnum.indexedHashed]: 'Hashed exact search',
    [SchemaFieldTypeEnum.encrypted]: 'Encrypted not searchable',
    [SchemaDataTypeEnum.json]: 'JSON',
    autoAssignKeys: 'Auto assign keys',
    contactUs: 'Need more fields? Contact us',
    path: 'Path',
    primaryKey: 'Primary key',
    primaryKeyHelper: 'Primary key should be a string containing only letters, numbers, and - _ symbols.',
    aliasPathDuplication: 'Only unique paths are allowed',
    youHaveUsedAllKeys: 'You have used all available schema fields. Contact us for more fields.',
    fields: 'Fields',
  },
  RestoreEnvironmentPage: {
    environments: 'Environments',
    restoreEnvironment: 'Restore environment',
    restore: 'Restore',
    areYouSure: 'Are you sure?',
    description:
      'All the Storage Credentials, services, encryption keys, and records stored within this environment will be unlocked.',
  },
  EnvironmentFirewallForm: {
    ipAllowList: 'Allowed IP addresses',
    invalidIpAddress: 'Invalid IP address',
    allowRequests: 'Enable firewall for this country',
    blockRequests: 'Block requests outside country',
    enableFirewall: 'Enable firewall for this environment',
    confirm: 'Confirm',
    update: 'Update',
  },
  FirewallPage: {
    dataFirewall: 'Data Firewall',
  },
  EnvironmentFirewallRuleForm: {
    ipAllowList: 'Allowed IP addresses',
    invalidIpAddress: 'Invalid IP address',
    sort: 'Sort',
    addIpAddress: 'Add IP address',
    allowedIPAddressesEmptyState: 'You can create a list of IP address exceptions for data firewall in each country.',
    remove: 'Remove',
  },
  EnvironmentActivateCountryPage: {
    activateCountry: 'Activate country',
    verification: 'Please verify the values',
    environments: 'Environments',
    restapiGuideTitle: 'Communicate regulated data through the REST API',
    salesforceGuideTitle: 'Integrate Salesforce with {{brandingTitle}}',
    gettingStarted: 'Getting Started',
    desc: 'Select country to activate for data residency',
    dashboardActivate: 'In order to use Dashboard activate at least one country',
  },
  ActivateEnvironmentCountryForm: {
    country: 'Country',
    desc: 'Select country to activate for data residency',
    environments: 'Environments',
    mt: 'MULTI-TENANT',
    st: 'SINGLE-TENANT',
  },
  SidebarLayout: {
    environments: 'Environments',
    services: 'Services',
    encryptionKeys: 'Encryption Keys',
    dataFirewall: 'Data Firewall',
    maxEnvironmentLimitReached: 'Maximum limit of environments has been reached',
    addEnvironment: 'Add environment',
  },
  NavigationSidebar: {
    environments: 'Environments',
    services: 'Services',
    encryptionKeys: 'Encryption Keys',
    dataFirewall: 'Data Firewall',
    maxEnvironmentLimitReached: 'Maximum limit of environments has been reached',
    addEnvironment: 'Add environment',
    schema: 'Schema',
    accessPolicies: 'Access Policies',
    logs: 'Logs',
    scheduledForDeletion: 'Pending deletion',
    restoreEnvironment: 'Restore environment',
    restoreEnvitonmentAlert:
      'The deletion of this environment is scheduled for {{date}}. All storage credentials, services, encryption keys, and records stored within this environment are temporarily locked. They will be automatically wiped along with the environment. If the environment was scheduled for deletion accidentally, you can restore it by clicking "Restore environment" button. The complete deletion of the environment may require a significant amount of time, depending on the amount of stored data and the current load. Once the environment and all its associated data are deleted, you will receive an email notification.',
    cantRestoreEnvironment: 'Cannot restore environment: you have reached the limit of {{envLimits}} environments. Please remove an existing environment and try again.',
  },
  ServiceNameDisplayComponent: {
    restApi: 'REST API',
    webServicesBorder: 'Web Services',
    restapiWeb: 'Frontend REST API',
    residentFunctions: 'Resident functions',
  },
  ManageEnvironmentConfigurationPage: {
    selectOperation: 'Select operation',
    verification: 'Please verify the values',
    confirm: 'Confirm',
    titleExport: 'Export environment configuration',
    titleImport: 'Importing environment configuration',
    operationEnvironment: '{{operation}}ing environment metadata',
    operationService: '{{operation}}ing services',
    operationBorder: '{{operation}}ing border configuration',
    operationFirewall: '{{operation}}ing data firewall configuration',
    operationResidentFunctions: '{{operation}}ing resident functions',
    success: 'Success',
    exportEnvironmentConfiguration: 'Export environment configuration',
    importEnvironmentConfiguration: 'Import environment configuration',
  },
  EnvironmentConfigurationManagementForm: {
    export: 'Export environment configuration',
    exportDesc: 'You can export the environment configuration, including its key-alias mappings, data firewall settings, Border endpoints, and resident functions.',
    import: 'Import environment configuration',
    importDesc: 'You can import the environment configuration, including its key-alias mappings, data firewall settings, Border endpoints, and resident functions.',
    exportEnvironmentConfiguration: 'Export environment configuration',
    importEnvironmentConfiguration: 'Import environment configuration',
    actionimport: 'Import',
    actionexport: 'Export',
  },
  ServiceLogsComponent: {
    dateTime: 'Date/time',
    severity: 'Severity',
    logMessage: 'Log message',
    request_id: 'Request ID',
    message_id: 'Message ID',
    report_id: 'Report ID',
    request_id_internal: 'Request ID internal',
    searchBy: 'Search by',
    search: 'Search',
    noLogsAvailable: 'No logs available',
    logResultExceedsLimit: 'To ensure optimal performance, the log download feature has a limit of {{limit}} logs per download. Please consider refining your search criteria to stay within the limit.',
    country: 'Country',
    service: 'Service',
    endpoint: 'Endpoint',
    downloadLogs: 'Download logs',
    logs: 'Logs',
  },
  BorderLogsPage: {
    borderlogs: 'Border logs',
    environments: 'Environments',
    country: 'Country',
    webServices: 'Web Services',
    endpoints: 'Endpoints',
  },
  EmailGatewayLogsPage: {
    environments: 'Environments',
    country: 'Country',
    emailGateway: 'Email Gateway',
    emailGatewayLogs: 'Email Gateway logs',
  },
  DashboardNoData: {
    noUsageData: 'No usage data available for reporting.',
    noUsageDataDetails1: 'It seems that you have not saved any regulated records to InCountry Vault.',
    noUsageDataDetails2: 'Please create an environment and create several records within it. Shortly after, usage reports will be updated.',
    openEnvironments: 'Open environments',
  },
  RestApiServiceCreatePage: {
    addRestApi: 'Add REST API service',
    create: 'Create',
  },
  RestApiServiceEditPage: {
    updateRestApi: 'Update REST API service',
    update: 'Update',
  },
  ResidentFunctionsServiceCreatePage: {
    addResidentFunctions: 'Add Resident Functions',
    serviceCredsWarning: 'These are service credentials. Please use them only when you need to manage resident functions through the {{brandingTitle}} Portal.',
    serviceSdkId: 'Service client ID',
    serviceSdkSecret: 'Service client secret',
    frontendCredsDesc: 'To call Resident Functions from your frontend application, use the following credentials:',
    makeSureToCopy: 'Make sure to copy this information now. You will no longer be able to see it again!',
    sdkId: 'Client ID',
    sdkSecret: 'Client secret',
    environmentId: 'Environment ID',
    restApiAddress: 'Rest API address',
    publishResidentFunction: 'Publish resident function',
  },
  CreateResidentFunctionsServiceForm: {
    callingApplicationFrontend: 'Calling from the application frontend',
    aclEndpointUrl: 'ACL endpoint URL',
    aclEndpointDesc: 'URL of the endpoint that regulates access control levels to records within the application',
    notValidTargetUrl: 'ACL endpoint URL must use the HTTPS protocol',
    aclEndpointWarning: 'Before enabling the Calling from the application frontend option, please verify that your ACL endpoint is implemented and operates correctly.',
  },
  ConfirmEmailPage: {
    backToLogin: 'Back to login page',
    cantConfirmEmail: 'Cannot confirm your email',
    cantConfirmEmailDetails1: "It seems like there might be an issue with the confirmation token. Please, go back to the email you received from us and make sure you're using the latest confirmation email.",
    cantConfirmEmailDetails2: "If you're still having trouble confirming your email, don't worry! You can resend the confirmation token again by clicking ",
  },
  BorderMaskingSettings: {
    maskingAfterNCharacters: 'Masking after N characters',
    maskingCharacter: 'Masking character',
    maskingLength: 'Masking length',
    valueDelimiter: 'Value delimiter',
    default: 'default',
    masking: 'data masking',
    fixed: 'fixed',
    value: 'Value',
    type: 'Type',
    strategy: 'Strategy',
    ' ': 'Space',
    _: 'Underscore (_)',
    '/': 'Slash (/)',
    '-': 'Hyphen (-)',
    algorithm: 'Algorithm',
    pathAlreadyAdded: 'The same path is used multiple times in the unredaction rule'
  },
  BorderRedactionRuleDlpExcludedPaths: {
    pathToExclude: 'Path to exclude',
    removePath: 'Remove path',
    excludePath: 'Exclude path',
  },
  ResidentFunctionsLogsPage: {
    residentFunctionsLogs: 'Resident functions logs',
    environments: 'Environments',
    country: 'Country',
    webServices: 'Web Services',
    endpoints: 'Endpoints',
  },
  MigrationSdkComponent: {
    generateCredentials: 'Migration client',
    create: 'Create',
    authEndpoint: 'Authentication endpoint',
    portalAddress: 'Portal address',
    clientId: 'Client ID',
    clientSecret: 'Client secret',
    environmentId: 'Environment ID',
    makeSureToCopy: 'Make sure to copy this information now. You will no longer be able to see it again!',
    generateCredentialsDescr: 'To export/import environment configurations from your script, create a migration client.',
    sdkCredentialsCreated: 'Migration client has been generated. Follow the instructions below to proceed.',
    instructions: 'Instructions',
    migrationSdkInstructions1: 'Step 1: Retrieve the authentication token by using the code snippet provided below.',
    migrationSdkInstructions2: 'Step 2: Utilize the specified endpoints for exporting/importing environment configurations.',
    scope: 'Scope',
  },
  EnvironmentPoliciesPage: {
    accessPolicies: 'Access Policies',
    createPolicy: 'Create policy',
    name: 'Name',
    schemaTable: 'Schema Table',
    edit: 'Edit',
    delete: 'Delete',
  },
  EnvironmentPolicyCreatePage: {
    addAccessPolicy: 'Add Access Policy',
  },
  EnvironmentPolicyForm: {
    permittedFields: 'Permitted fields',
    scope: 'Scope',
    name: 'Name',
    schemaTable: 'Schema Table',
    actions: 'Actions',
    fields: 'Fields',
    addMasking: 'Add masking',
    masking: 'Masking',
    noAvailableNextKeys: 'There is no available keys to add. If you need additional keys, add them in the Schema Editor.',
    addScopeField: 'Add scope field',
    externalAclSupport: 'External ACL support',
    externalValidationEndpoint: 'External validation endpoint',
    corsSettings: 'Custom CORS settings',
    allowAnyOrigin: 'Allow any origin',
    allowOriginList: 'Allow Origin List',
    notValidTargetUrl: 'Target URL must use the HTTPS protocol',
    invalidOrigin: 'Allow Origin URL must use the HTTPS protocol',
    addOrigin: 'Add origin',
    removeOrigin: 'Remove origin',
    removeCorsSettings: 'Remove CORS settings',
    fieldsProtectionMode: 'Fields protection mode',
    [PoliciesFieldProtectionMode.restricted]: 'Restricted',
    [PoliciesFieldProtectionMode.protected]: 'Protected',
    [PoliciesFieldProtectionMode.forced]: 'Forced',
    aclMustNotContainUppercase: 'The access policy name cannot contain uppercase letters.',
    forcedModeDesc: 'This mode will respond with masked/redacted values regardless of the country of residence. It will bypass the data firewall, allowing the masked/redacted record to be accessed from anywhere.',
    protectedModeDesc: 'This mode will allow handling original data within the country while always applying masking/redaction for any cross-border requests. In that case, if the data firewall is set to deny the request, access to the record will be forbidden.',
    restrictedModeDesc: 'This mode will always apply masking/redaction. If a data firewall is set up, access to the record will be denied based on its rules. To see more mode options, add a permitted field with the value type set to "masked" or "redacted."',
  },
  EnvironmentPolicyMaskingField: {
    maskingAfterNCharacters: 'After N characters',
    maskingCharacter: 'Character',
    maskingLength: 'Length',
    valueDelimiter: 'Value delimiter',
    default: 'default',
    fixed: 'fixed',
    value: 'Value',
    type: 'Type',
    strategy: 'Strategy',
    ' ': 'Space',
    _: 'Underscore (_)',
    '/': 'Slash (/)',
    '-': 'Hyphen (-)',
    algorithm: 'Algorithm',
    field: 'Field',
    removeMasking: 'Remove masking',
    maskingNotSupported: 'Masking not supported',
    valueType: 'Value type',
    transforms: 'Transforms',
    format: 'Format',
  },
  EnvironmentPolicyDeletePage: {
    deletePolicy: 'Delete policy',
    environments: 'Environments',
    areYouSure: 'Do you really want to delete this policy?',
    thisWillDelete: 'This will delete the policy `{{policyName}}` for the `{{name}}` environment.',

  },
  EnvironmentPolicyUpdatePage: {
    updateAccessPolicy: 'Update Access Policy',
  },
  RestapiServiceCreatePage: {
    addRestApi: 'Add Backend REST API',
    create: 'Create',
  },
  BorderRedactionRuleExtendedCollection: {
    collection: 'Collection',
    collectionName: 'Collection name',
    required: 'Required',
    entityIdPath: 'Entity ID path',
    entityErrorCorrectionFieldPath: 'Entity error correction field path',
    strategies: 'Strategies',
    searchable: 'Searchable',
    globalEntityId: 'Global entity ID',
    nonUniquePrefix: 'Prefix to add to non-unique ID',
    pathUniqueId: 'Path to unique ID',
    pathNonUniqueId: 'Path to non-unique ID',
    tokenizedField: 'Randomly-tokenized field',
    uniqueSelectedDesc:
      'In the Properties section, you indicated that your entities have unique IDs. Please provide the path to the unique ID of this entity.',
    notUniqueSelectedDesc:
      'In the Properties section, you indicated that your entities do not have unique IDs. Please specify a unique prefix for this entity and the path to the non-unique ID.',
    notRandomStrategySelected:
      'You selected the algorithm that produces non-random redacted values. It is recommended to select the alphaNumeric, alphaNumericLowerCase, alphaPrepended, email, or dateISO algorithm..',
    entityIdPathHelper: 'Specify the path for locating entity IDs within the request payload when the endpoint is intended for updates. This field is optional.',
    entityIdPathResponseHelper: 'Specify the path for locating entity IDs within the response payload. This field is required.',
    errorCorrectionFieldHelper: 'Please provide the path to the record identifier found in the response to this request. If the required path is not present in the list, include it in the strategies using one of the following algorithms: alphaNumeric, alphaNumericLowerCase, alphaPrepended, email, or dateISO algorithm.',
    errorCorrectionResponseFieldHelper: 'Please provide the path to the record identifier found in the response to this request.',
    collectionNameHelper:
      'Please give a meaningful and unique name to the entity that will allow the endpoint to correctly differentiate between entities even when they have the same identifier',
    stepSalesforceTitle: 'Step {{step}}: Salesforce Cross-Border parameters',
    stepSalesforceDesc: 'To configure Salesforce Cross-Border, please, provide the following parameters. To learn more about Salesforce Cross-Border, follow the',
    selectSchemaModel: 'Step 2: Schema',
    selectSchemaModelDesc:
      '{{brandingTitle}} allows you to define schema of your records for more convenient execution of requests against regulated values. You can find more details about this feature in the',
    stepRedactionTitle: 'Step {{step}}: Fields to redact',
    stepRedactionDesc:
      '{{brandingTitle}} requires the selection of an algorithm for each field you want to redact in the request payload (JSON). Please select the appropriate algorithm for each redacted field. You can find the details on each algorithm in the',
    documentation: 'documentation',
    stepEntityTitle: 'Step {{step}}: Entity identification',
    stepEntityTitleDelete: 'Step {{step}}: Entity identification',
    notUniqueSelectedDeleteDesc:
      'In the Properties section, you indicated that your entities do not have unique IDs. Please specify a unique prefix for this entity.',
    allOrNoneFlagPath: 'allOrNone parameter',
    recordsPath: 'Records path',
    updateByExternalIdFlag: 'Update by External ID',
    externalIdPath: 'External ID path',
    searchPath: 'Search path',
    stepDlpTitle: 'Step {{step}}: Data Loss Prevention (DLP) excluded paths',
    stepDlpDesc: 'By default, {{brandingTitle}} Border automatically detects and redacts potential personally identifiable information (PII) data in the payload when it crosses borders. If you wish to exempt specific paths from this redaction, please utilize the form provided below.',
    requestCollectionPathHelper: 'Specify path to the entity in the request payload',
    responseCollectionPathHelper: 'Specify path to the entity in the response payload',
    collectionPath: 'Entity path',
    entityName: 'Entity name',
    identifyEntity: 'Specify the JSON path to the entity in both the request and response payloads.',
    deleteEntity: 'Delete entity',
    stepTokenizedTitle: 'Step {{step}}: Randomly-tokenized field',
    stepTokenizedDesc: "Specify a field that guarantees a unique token for clear record matching without any chance of collisions.",
    advancedSettings: 'Advanced settings',
    operationMode: 'Operation mode',
    processing: 'Processing',
    processingAndStorage: 'Processing & Storage',
    schemaTable: 'Schema Table',
  },
  BorderRedactionRuleExtendedCollections: {
    extendedRuleDescription: 'The extended redaction rule facilitates batch creation or updating of records. Begin by adding entities and configuring the path to these entities in both request and response payloads.',
    addEntity: 'Add entity',
  },
  EnvironmentPolicyScopeField: {
    field: 'Field',
    filterType: 'Filter type',
    filterValue: 'Filter value',
    operator: 'Operator',
    value: 'Value',
    array: 'Array of values',
    notValue: 'Not value',
    notArrayOfValues: 'Not array of values',
    comparison: 'Comparison',
    '$gt': 'Greater than',
    '$gte': 'Greater than or equal',
    '$lt': 'Less than',
    '$lte': 'Less than or equal',
    add: 'Add',
    remove: 'Remove',
  },
  EnvironmentLogsPage: {
    logs: 'Logs',
  },
  ServiceLogsComponentFilter: {
    service: 'Service',
    country: 'Country',
    endpoint: 'Endpoint',
    functionName: 'Function name',
  },
  DeletedCompanyPage: {
    deletedCompany: 'Deletion scheduled',
    deletedCompanyDesc: "The organization's owner has scheduled the deletion of the organization on <strong>{{date}}</strong>. If you wish to be excluded from this organization, please contact the owner.",
  },
  RestApiServiceManageCredentialsPage: {
    manageRestApiCredentials: 'Manage REST API credentials',
    accessPolicy: 'Access Policy',
    created: 'Created',
    name: 'Name',
    createNewCredentials: 'Create new credentials',
    deleteCredentials: 'Delete credentials',
    renameCredentials: 'Rename credentials',
    startWithRestApiCreds: 'To begin using the REST API with ACL, create new credentials here.'
  },
  RestApiServiceCreateCredentialsPage: {
    newRestApiCredentials: 'New REST API credentials',
  },
  RestApiServiceDeleteCredentialsPage: {
    name: 'Name',
    back: 'Back',
    confirmation: 'Verification',
    confirm: 'Confirm',
    next: 'Next',
    delete: 'Delete',
    deleteCredentials: 'Delete REST API credentials',
    areYouSure: 'Do you really want to delete these Storage Credentials?',
    thisWillDelete:
      'This will permanently delete the `{{name}}` REST API Credentials.\nThis action cannot be undone.',
  },
  RestApiServiceUpdateCredentialsPage: {
    name: 'Name',
    confirm: 'Confirm',
    next: 'Next',
    update: 'Update',
    cancel: 'Cancel',
    back: 'Back',
    renameCredentials: 'Rename REST API credentials',
  },
  useResidentFunctions: {
    refreshCache: 'Refresh cache',
    refreshingCache: 'Refreshing cache...',
    residentFunctionCacheOutdated: 'Resident function list cache is outdated. Last sync {{syncDate}}',
    authentication: 'Resident functions service authentication',
  },
  SdkCredentialsInputDialog: {
    inputClientSecret: "To refresh the resident functions list cache, please enter the Client Secret for the Resident Function service. If you've lost or forgotten the Client Secret, you can renew it on the Services page.",
    clientId: 'Client ID',
    clientSecret: 'Client Secret',
    invalidClient: 'You have entered an invalid client secret',
  },
  BorderRedactionRuleQueryStrategies: {
    queryParameter: 'Query parameter',
    redactQuery: 'Add redacted query',
    stepQueryStrategy: 'Step {{step}}: Query parameters to redact',
    stepQueryStrategyDesc: 'If you have query parameters that contain data to be redacted, specify them here.'
  },
};

const flatted = { values: translation };

export default flatted;
