/* eslint-disable */
import { get } from 'lodash';

import Country from 'app/api/models/Country';
import { IRow } from 'app/components/common/EnhancedTable';
import { TableSortOrder } from 'app/types/table';

export const desc = (a: any, b: any, orderBy: string): number => {
  if (a && b && orderBy) {
    if (get(b, orderBy) < get(a, orderBy)) {
      return -1;
    }
    if (get(b, orderBy) > get(a, orderBy)) {
      return 1;
    }
  }

  return 0;
};

export const stableSort = (array: IRow[], cmp: (order: TableSortOrder, orderBy: string) => number): IRow[] => {
  if (Array.isArray(array) && cmp) {
    const stabilizedThis = array?.map((el, index) => [el, el.payload, index]);
    stabilizedThis.sort((a, b) => {
      const order = cmp(a[1], b[1]);
      if (order !== 0) {
        return order;
      }

      return a[2] - b[2];
    });

    return stabilizedThis?.map(el => el[0]);
  }

  return [];
};

export const getSorting = (order: TableSortOrder, orderBy: string) => {
  return order === TableSortOrder.dsc ? (a: any, b: any) => desc(a, b, orderBy) : (a: any, b: any) => -desc(a, b, orderBy);
};

export const sortCountriesByName = (countries: Country[]): Country[] => {
  return countries.sort((a, b) => a?.name?.localeCompare(b?.name));
};
