import { ResidentFunctionsResponseInterface, ResidentFunctionsResponseMetaInterface } from 'app/types/residentFunctions';
import ResidentFunction from './ResidentFunction';
import ResidentFunctionMeta from './ResidentFunctionMeta';

class ResidentFunctions {
  data: ResidentFunction[];
  meta: ResidentFunctionMeta;
  isCached?: boolean;
  toJSON: () => ResidentFunctionsResponseInterface;
  constructor(data: unknown) {
    if (isResidentFunctionsResponse(data)) {
      this.data = data.data?.map(v => new ResidentFunction(v));
      this.meta = new ResidentFunctionMeta(data?.meta);
      this.isCached = data?.is_cached;
      this.toJSON = () => ({
        data: this?.data?.map(v => ({
          script_name: v.name,
          script_body: v.body,
          created_at: v.createdAt.toISOString(),
          updated_at: v.updatedAt.toISOString(),
          publish_version: v?.publishVersion,
        })),
        meta: {
          ...this?.meta,
          ...(this?.meta?.expiredAt ? { expired_at: this?.meta?.expiredAt?.toISOString() } : {})
        },
      })
    } else {
      throw new Error('Data is not ResidentFunctionsResponse');
    }
  }
}

const isResidentFunctionsResponse = (data: unknown): data is ResidentFunctionsResponseInterface => {
  const d = data as ResidentFunctionsResponseInterface;
  return d?.data !== undefined;
};

export default ResidentFunctions;
