import { useMemo } from 'react';
import { useRouter } from 'next/router';

import { configureI18n } from 'app/utils/i18n';

const useI18nConfigurator = () => {
  const router = useRouter();
  const locale = router?.locale || 'en';
  useMemo(() => configureI18n(locale), [locale]);
};

export default useI18nConfigurator;
