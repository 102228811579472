import React, { ReactNode } from 'react';
import styled from '@emotion/styled';

import { CurrentUser } from 'app/api/models/CurrentUser';
import Footer from 'app/components/layout/Footer';
import MainMenu from 'app/components/layout/MainMenu';
import { colors } from 'app/theme-colors';
import { device } from 'app/utils/styles/mediaSizes';
import { Alert, Box } from '@mui/material';
import { formatDate } from 'app/utils/dates';

interface IProps {
  user?: CurrentUser;
  children: ReactNode;
  hideMenu?: boolean;
}

const AppLayout: React.FC<IProps> = ({ user, children, hideMenu }) => {
  const renderOrgDeletionAlert = () => {
    if (user?.defaultOrg?.removedAt) {
      return (
        <Box>
          <Alert severity='warning'>
            Attention! This organization is scheduled for deletion and will be deleted on <b>{formatDate(new Date(user?.defaultOrg?.removedAt))}</b>.
          </Alert>
        </Box>
      )
    }
    return null;
  }

  return (
    <Wrapper>
      {renderOrgDeletionAlert()}
      <PageWrapper>
        {!hideMenu && user && <MainMenu user={user} />}
        <ContentWrapper>{children}</ContentWrapper>
        <Footer />
      </PageWrapper>
    </Wrapper>
  );
}

const FlexWrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
`;

const Wrapper = styled(FlexWrapper)`
  background-color: #fafaf8;
  background-image: linear-gradient(top, #fff 0%, #fafaf8 400px);
  background-image: -webkit-linear-gradient(top, #fff 0%, #fafaf8 400px);
  align-items: center;
  border-top: 4px solid ${colors.alternative.light};
  box-sizing: border-box;
  min-height: calc(100vh - 4px);
`;

const PageWrapper = styled(FlexWrapper)`
  min-height: calc(100vh - 4px);
  min-width: 1280px;
  width: 100%;
  margin: 0 16px;

  @media ${device.laptop} {
    max-width: 1280px;
  }
  @media ${device.laptopL} {
    max-width: 1440px;
  }

  @media ${device.desktop} {
    max-width: 1920px;
  }
`;

const ContentWrapper = styled(FlexWrapper)`
  min-height: calc(100vh - (90px + 100px + 4px));
`;

export default AppLayout;
