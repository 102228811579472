import React, { FC } from 'react';
import styled from '@emotion/styled';

import PortalConfig from 'app/api/models/PortalConfig';
import LogoV2 from 'app/components/common/LogoV2';
import FooterMenu from 'app/components/layout/FooterMenu';
import { colors } from 'app/theme-colors';
import FooterDetails from '../common/FooterDetails';

const Footer: FC = () => {
  return (
    <Wrapper>
      <Row>
        <StyledLogoV2 />
        <span>{PortalConfig.longTitle}</span>
        <StyledFooterMenu />
      </Row>
      <Row>
        <Centered>
          <FooterDetails />
        </Centered>
      </Row>
    </Wrapper>
  );
};

const Row = styled.div`
  display: flex;
  align-items: center;
  justify-items: center;
  gap: 32px;
  flex-flow: row nowrap;
  margin: ${({ theme }) => theme.spacing(2, 0, 0, 0)};
`;

const Centered = styled.div`
  margin: 0 auto;
`;

const Wrapper = styled.div`
  opacity: 0.5;
  transition: 0.3s opacity;
  font-size: 12px;
  &:hover {
    opacity: 0.7;
  }
`;

const StyledFooterMenu = styled(FooterMenu)`
  margin-left: auto;

  & a {
    color: ${colors.text.primary};
  }
`;

const StyledLogoV2 = styled(LogoV2)`
  fill: ${PortalConfig.isDefault() || PortalConfig.isDCC() ? colors.text.primary : colors.primary.main};
  width: 85px !important;
  min-width: 85px !important;
  path {
    fill: ${PortalConfig.isDefault() || PortalConfig.isDCC() ? colors.text.primary : colors.primary.main};
  }
`;

export default Footer;
