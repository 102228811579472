export enum COOKIES_TYPES {
  AUTH = 'auth',
  CSRF = 'csrf',
  EXP = 'exp',
}

export enum AUTH_COOKIE_VALUES {
  LOGGED_IN = 'loggedIn',
  LOGGED_OUT = 'loggedOut',
}

class CookiesService {
  getCookie = (name: string, source: string) => {
    const value = `; ${source}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts?.pop()?.split(';').shift();
  };

  hasExpCookie = (source: string) => !!this.getCookie(COOKIES_TYPES.EXP, source);

  isLoggedIn = (source: string) => this.hasExpCookie(source) && this.getAccessTll(source) > 0;

  getAccessTll = (source: string) => {
    const expCookie = this.getCookie(COOKIES_TYPES.EXP, source);
    if (!expCookie) return 0;
    const expTime = new Date(expCookie).getTime();
    return Math.round((expTime - Date.now()) / 1000);
  };
}

export const cookiesService = new CookiesService();
