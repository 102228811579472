import { NextPageContext } from 'next';

export const getCorId = (ctx: NextPageContext) => {
  const rawHeaders = ctx?.res?.socket?.parser?.incoming?.rawHeaders as string[];
  const corIdIdx = rawHeaders?.findIndex(el => el === 'x-inc-corr-id-int') + 1;
  let corId = 'cant-find-x-inc-corr-id-int';
  if (typeof corIdIdx === 'number' && corIdIdx !== -1) {
    corId = rawHeaders?.[corIdIdx];
  }

  return corId;
}
