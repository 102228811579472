import { ServiceResponse, ServiceTypeResponse } from 'app/types/services';

class Service {
  serviceType: ServiceTypeResponse;
  serviceId: string;
  serviceName: string;
  sdkId?: string;
  sdkName?: string;
  sdkSecret?: string;
  sdkCreatedAt?: string;
  sdkUpdateAt?: string;
  entryUrl?: string;
  ossEnabled?: boolean;
  aclEndpoint?: string;
  serviceSdkId?: string;
  serviceSdkSecret?: string;
  allowedOrigin?: string | string[];
  authUrl?: string;
  constructor(data: unknown) {
    if (isServiceResponse(data)) {
      this.serviceId = data.id;
      this.serviceName = data.name;
      this.serviceType = data.type;
      this.sdkId = data?.sdk?.id;
      this.sdkName = data?.sdk?.name;
      this.sdkSecret = data?.sdk?.secret;
      this.sdkCreatedAt = data?.sdk?.created_at;
      this.sdkUpdateAt = data?.sdk?.updated_at;
      this.entryUrl = data.entry_url;
      this.ossEnabled = data.oss_enabled;
      this.aclEndpoint = data?.aclEndpoint;
      this.serviceSdkId = data?.serviceSdk?.id;
      this.serviceSdkSecret = data?.serviceSdk?.secret;
      this.allowedOrigin = data?.allowedOrigin;
      this.authUrl = data?.auth_url;
    } else {
      throw new Error('Data is not ServiceResponse');
    }
  }
}

const isServiceResponse = (data: unknown): data is ServiceResponse => {
  const d = data as ServiceResponse;
  return d?.id !== undefined && d?.name !== undefined && d?.type !== undefined && d?.sdk !== undefined;
};

export default Service;
