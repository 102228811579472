import React from 'react';
import styled from '@emotion/styled';

interface IProps {
  active?: boolean;
}

const DropdownIcon: React.FC<IProps> = ({ active }) => {
  return (
    <Wrapper>
      <StyledImage xmlns='http://www.w3.org/2000/svg' className={active ? 'active' : ''} viewBox='0 0 512 512'>
        <path d='M128.4 160L96 192.3 256 352l160-159.7-32.4-32.3L256 287.3z' />
      </StyledImage>
    </Wrapper>
  );
};

export default DropdownIcon;

const Wrapper = styled.div`
  position: relative;
  height: 10px;
  width: 10px;
  display: flex;
  align-items: center;
  margin-left: 2px;
  margin-top: 2px;
`;

const StyledImage = styled.svg`
  .active {
    transform: rotate(180deg);
  }
`;
