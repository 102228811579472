import EncryptionKey, {
  AwsEncryptionKeyRequestBody,
  EncryptionKeyRequestBody,
  InCountryEncryptionKeyRequestBody,
} from 'app/api/models/EncryptionKey';
import { FormInputs as CreateEncryptionKeyFormInputs } from 'app/forms/CreateEncryptionKeyForm';
import {
  EncryptionKeyRotationPeriodInterface,
  EncryptionKeyRotationPeriodValues,
  EncryptionKeyType,
  EncryptionKeyTypesEnum,
} from 'app/types/encryption';
import { CountryTenant } from 'app/types/infraJson';

export const prepareNewEncryptionKeyPayload = (
  envId: string,
  countryCode: string,
  tenant: CountryTenant,
  values: Partial<CreateEncryptionKeyFormInputs>,
): EncryptionKeyRequestBody => {
  const label = [envId, countryCode, tenant, values.keyType].join(':');
  switch (values.keyType) {
    case EncryptionKeyTypesEnum.MANUAL_MANAGED_AWS:
      return new AwsEncryptionKeyRequestBody(
        values.keyType,
        label,
        values.region,
        values.encryptedKey,
        values.customerManagedKey,
        values.iamAccessKeyId,
        values.iamSecretKey,
      );
    case EncryptionKeyTypesEnum.INCOUNTRY_MANAGED:
    default:
      return new InCountryEncryptionKeyRequestBody(values.keyType, label, values.rotationPeriod);
  }
};

export const isInCountryManagedKey = (key?: EncryptionKey | undefined) =>
  key && key?.type === EncryptionKeyTypesEnum.INCOUNTRY_MANAGED;

export const ROTATION_PERIODS: EncryptionKeyRotationPeriodInterface[] = [
  {
    name: 'oneMonth',
    value: EncryptionKeyRotationPeriodValues.MONTH,
  },
  {
    name: 'twoMonths',
    value: EncryptionKeyRotationPeriodValues.TWO_MONTH,
  },
  {
    name: 'sixMonths',
    value: EncryptionKeyRotationPeriodValues.HALF_YEAR,
  },
  {
    name: 'oneYear',
    value: EncryptionKeyRotationPeriodValues.YEAR,
  },
];

export const EncryptionKeyTypeLabels: Record<EncryptionKeyType, string> = {
  [EncryptionKeyTypesEnum.INCOUNTRY_MANAGED]: 'incountryKeyMgmt',
  [EncryptionKeyTypesEnum.MANUAL_MANAGED_AWS]: 'manualKeyMgmtAws',
};
