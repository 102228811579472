import { ServerErrorItemInterface } from 'app/utils/errors';

export enum Roles {
  owner = 'owner',
  administrator = 'administrator',
  auditor = 'auditor',
  manager = 'manager',
  member = 'member',
  guest = 'guest',
}

export interface ICheckCodeResponse {
  errors?: ServerErrorItemInterface[];
  time: string;
  totp: boolean;
  valid: boolean;
}
