import { initReactI18next } from 'react-i18next';
import { use } from 'i18next';

import PortalConfig from 'app/api/models/PortalConfig';
import { translation as enTranslation } from 'app/locales/en';
import { translation as ruTranslation } from 'app/locales/ru';

export const configureI18n = (lng?: string) => {
  use(initReactI18next).init({
    resources: {
      en: {
        ...enTranslation,
      },
      ru: {
        ...ruTranslation,
      },
    },
    lng,
    fallbackLng: 'en',

    interpolation: {
      escapeValue: false,
      alwaysFormat: true,
      format: (value, format, lng, edit) => {
        if (edit?.interpolationkey === 'brandingTitle') {
          return PortalConfig.title;
        }
        if (edit?.interpolationkey === 'longBrandingTitleWithAbbreviation') {
          return PortalConfig.longTitle.split('-').slice(0, 1).join(' ');
        }
        if (edit?.interpolationkey === 'longBrandingTitleDCC') {
          return PortalConfig.longTitle.split(',')?.[0];
        }
        if (edit?.interpolationkey === 'longBrandingTitle') {
          return PortalConfig.longTitle.split('(ACIS)').slice(0, 1).join(' ');
        }
        if (edit?.interpolationkey === 'brandingAddress') {
          return PortalConfig.address;
        }
        if (edit?.interpolationkey === 'brandingWebsite') {
          return PortalConfig.websiteTitle;
        }
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        return value;
      },
    },
  });
};
