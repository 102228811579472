import { add, format } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';

import { DateFormat } from 'app/types/dates';

export const formatDate = (date: Date): string => format(date, DateFormat.short);
export const formatDateLongFraction = (date: Date): string => format(date, DateFormat.longFraction);
export const formatDateLong = (date: Date): string => format(date, DateFormat.long);

export const formatUtcToLocal = (date: Date): Date =>
  utcToZonedTime(date, Intl.DateTimeFormat().resolvedOptions().timeZone);

export const addSecondsToCurrentTime = (seconds: number): Date => {
  return add(new Date(), { seconds });
};

export function removeZeroUtcOffset(date: Date): Date {
  return new Date(date.toISOString().slice(0, -1));
}
