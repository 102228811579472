import * as React from 'react';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { IconButton, Menu, MenuItem } from '@mui/material';

export interface ContextMenuOptions<T> {
  title: string;
  label: string;
  value: string | T;
  selected?: boolean;
}

interface IProps {
  options: ContextMenuOptions<string>[];
  handler?: (value?: string) => string;
}

const ContextMenu: React.FunctionComponent<IProps> = ({ options, handler }) => {
  const [anchorEl, setAnchorEl] = React.useState<(EventTarget & HTMLElement) | null>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event?.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickListItem = (event: React.MouseEvent<HTMLElement>, value: string) => {
    setAnchorEl(null);
    if (handler && typeof handler !== 'undefined') {
      handler(value);
    }
  };

  return (
    <>
      <IconButton size='small' aria-label='more' aria-controls='long-menu' aria-haspopup='true' onClick={handleClick}>
        <MoreVertIcon fontSize='small' />
      </IconButton>
      <Menu id='long-menu' anchorEl={anchorEl} keepMounted={true} open={open} onClose={handleClose}>
        {options?.map(option => (
          <MenuItem
            key={option.value}
            selected={option.selected}
            onClick={event => handleClickListItem(event, option.value)}
          >
            {option.title}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default ContextMenu;
