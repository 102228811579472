import Country from 'app/api/models/Country';
import { CurrentUser } from 'app/api/models/CurrentUser';
import EncryptionKey from 'app/api/models/EncryptionKey';
import Environment from 'app/api/models/Environment';
import Invite from 'app/api/models/Invite';
import Member from 'app/api/models/Member';
import ServiceType from 'app/api/models/ServiceType';
import { sortCountriesByName } from 'app/utils/sorting';

interface PropType {
  $TypeID?: keyof typeof ConstructorDictionary;
}

const ConstructorDictionary = {
  Environment: Environment,
  CurrentUser: CurrentUser,
  Member: Member,
  Invite: Invite,
  EncryptionKey: EncryptionKey,
  ServiceType: ServiceType,
  Country: Country,
};

function createModels<T>(props: T): T {
  if (!props) return props;
  return Object.entries(props || {}).reduce((acc, [propKey, prop]: [string, PropType | PropType[]]) => {
    if (Array.isArray(prop)) {
      const constructedPropArr = prop?.map(arrProp => {
        if (arrProp.$TypeID && ConstructorDictionary[arrProp.$TypeID]) {
          return new ConstructorDictionary[arrProp.$TypeID](arrProp);
        }
        return arrProp;
      });
      if (prop?.[0]?.$TypeID === 'Country') {
        return { ...acc, [propKey]: sortCountriesByName(constructedPropArr as Country[]) };
      }
      return { ...acc, [propKey]: constructedPropArr };
    } else if (prop.$TypeID && ConstructorDictionary[prop.$TypeID]) {
      return { ...acc, [propKey]: new ConstructorDictionary[prop.$TypeID](prop) };
    } else {
      return { ...acc, [propKey]: prop };
    }
  }, {}) as T;
}

export default createModels;
