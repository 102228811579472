import React, { createContext, useContext, useMemo } from 'react';

import { OpenSnackbarType, useSnackbar } from 'app/utils/customHooks/useSnackbar';

export interface SnackbarContextInterface {
  openSnackbar: OpenSnackbarType;
  closeSnackbar: () => void;
}

const initialContext: SnackbarContextInterface = {
  openSnackbar: () => {
    /* */
  },
  closeSnackbar: () => {
    /* */
  },
};

export const SnackbarContext = createContext<SnackbarContextInterface>(initialContext);
export const useSnackbarContext = () => useContext(SnackbarContext);

const SnackbarProvider: React.FC = ({ children }) => {
  const { renderSnackbar, openSnackbar, closeSnackbar } = useSnackbar();

  const value = useMemo(
    () => ({
      openSnackbar,
      closeSnackbar,
    }),
    [openSnackbar, closeSnackbar],
  );

  return (
    <SnackbarContext.Provider value={value}>
      {children}
      {renderSnackbar()}
    </SnackbarContext.Provider>
  );
};

export default SnackbarProvider;
