import React from 'react';
import { EmotionCache } from '@emotion/cache';
import { CacheProvider, Global } from '@emotion/react';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import { AppContext, AppProps } from 'next/app';

import { CurrentUser } from 'app/api/models/CurrentUser';
import UserActivityComponent from 'app/components/common/UserActivityComponent';
import AppLayout from 'app/components/layout/AppLayout';
import NextHead from 'app/components/nextjs/NextHead';
import { theme } from 'app/theme-mui';
import { EXCLUDED_FROM_LAYOUT, RF_ROUTES, Routes } from 'app/utils/constants';
import Providers from 'app/utils/context/Providers';
import useI18nConfigurator from 'app/utils/customHooks/useI18nConfigurator';
import createModels from 'app/utils/models';
import createEmotionCache from 'app/utils/styles/createEmotionCache';
import { configureNprogress, nProgressCSS } from 'app/utils/styles/nprogress';
import Script from 'next/script';
import getConfig from 'next/config';
import { NextGetConfig } from 'app/types/nextjs';
import PortalConfig from 'app/api/models/PortalConfig';
import { getCorId } from 'app/utils/next/ssr';
import { configureRecaptchaNet } from 'app/utils/recaptcha/recaptcha';

import '../../styles/globals.css';
import '../../styles/flags.css';

const {
  publicRuntimeConfig: { GTM_CONTAINER_ID, GTM_ENVIRONMENT_ID, GTM_AUTH },
} = getConfig() as NextGetConfig;

configureNprogress();
configureRecaptchaNet();

interface ExtendedAppProps extends AppProps {
  pageProps: object;
  corId: string;
  emotionCache?: EmotionCache;
}

const App = ({
  Component,
  pageProps,
  corId,
  emotionCache = createEmotionCache(),
  router,
}: ExtendedAppProps): JSX.Element => {
  useI18nConfigurator();

  const renderComponent = () => {
    const props = createModels<object>(pageProps);
    const user = (props as unknown as { user: CurrentUser })?.user;
    if (user instanceof CurrentUser && !EXCLUDED_FROM_LAYOUT[router.pathname]) {
      return (
        <UserActivityComponent>
          <AppLayout user={user}>
            <Component {...props} />
          </AppLayout>
        </UserActivityComponent>
      );
    }

    return <Component {...props} />;
  };

  const addGtmScript = () => {
    if (PortalConfig.isDefault() && !RF_ROUTES.includes(router.pathname)) {
      return (
        <Script id="google-tag-manager" strategy="afterInteractive" nonce={corId}>
          {`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl+ '&gtm_auth=${GTM_AUTH}&gtm_preview=${GTM_ENVIRONMENT_ID}&gtm_cookies_win=x';var n=d.querySelector('[nonce]');
        n&&j.setAttribute('nonce',n.nonce||n.getAttribute('nonce'));f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','GTM-${GTM_CONTAINER_ID}');`}
        </Script>
      )
    }

    return null;
  }

  return (
    <CacheProvider value={emotionCache}>
      <Global styles={nProgressCSS} />
      <NextHead />
      {addGtmScript()}
      <MuiThemeProvider theme={theme}>
        <Providers user={(createModels<object>(pageProps) as unknown as { user: CurrentUser })?.user}>
          <CssBaseline />
          {renderComponent()}
        </Providers>
      </MuiThemeProvider>
    </CacheProvider>
  );
};

export default App;

App.getInitialProps = (data: AppContext) => {
  if (data?.ctx?.pathname?.includes(Routes.error.notFound().pathname) && Object.keys(data?.ctx?.query || []).length > 0) {
    data?.ctx?.res?.writeHead(302, {
      Location: Routes.error.notFound().pathname,
      'Content-Type': 'text/html; charset=utf-8',
    });
    data?.ctx?.res?.end();
  }

  return ({ corId: getCorId(data.ctx) });
}
