import { Dialog, DialogContent, DialogTitle, Typography } from '@mui/material';
import { DefaultTFuncReturn } from 'i18next';
import React, { useContext, createContext } from 'react';
import { v4 as uuidv4 } from 'uuid';

type MaxWidth = 'xs' | 'sm' | 'md' | 'lg' | false;

interface IWithDialogProps {
  openDialog: (
    dialogTitle: JSX.Element | string | DefaultTFuncReturn,
    dialogContent: JSX.Element,
    // tslint:disable-next-line: max-union-size
    maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | false,
  ) => string;
  closeDialog: (id: string) => void;
  hasOpenDialog: boolean;
}

class DialogConstructor {
  id: string;
  open: boolean;
  title: JSX.Element | string | null;
  content: React.ReactElement<any> | null;
  maxWidth: MaxWidth;

  constructor(
    title?: JSX.Element | string,
    content?: React.ReactElement<any>,
    maxWidth?: MaxWidth,
  ) {
    this.id = uuidv4();
    this.open = true;
    this.title = title || null;
    this.content = content || null;
    this.maxWidth = maxWidth || 'sm';
  }
}

interface IState {
  dialogs: DialogConstructor[],
}

const DialogProviderContext = createContext<IWithDialogProps>({
  openDialog: () => '',
  closeDialog: () => { return },
  hasOpenDialog: false,
});

export class DialogProvider extends React.Component<{}, IState> {
  constructor(props: {}) {
    super(props);

    this.state = {
      dialogs: [],
    }

    this.openDialog = this.openDialog.bind(this);
    this.closeDialog = this.closeDialog.bind(this);
  }

  openDialog(
    title: JSX.Element | string,
    content: JSX.Element,
    maxWidth?: MaxWidth,
  ): string {

    const newDialog = new DialogConstructor(title, content, maxWidth);

    this.setState({
      dialogs: [
        ...this.state.dialogs,
        newDialog,
      ],
    })

    return newDialog.id;
  }

  closeDialog(id: string): void {
    const newDialogs = this?.state?.dialogs?.filter(dialog => dialog.id !== id);
    this.setState({
      dialogs: newDialogs,
    })
  }

  render() {
    return (
      <DialogProviderContext.Provider
        value={{
          openDialog: this.openDialog,
          closeDialog: this.closeDialog,
          hasOpenDialog: this.state.dialogs.length > 0,
        }}
      >
        {this.props.children}
        {this?.state?.dialogs?.map(dialog => (
          <Dialog
            key={dialog.id}
            fullWidth={true}
            maxWidth={dialog.maxWidth}
            open={dialog.open}
          >
            {dialog.title && (
              <DialogTitle variant='h4'>
                <div className='text-left'>
                  {dialog.title}
                </div>
              </DialogTitle>
            )}
            <DialogContent>
              <Typography variant='subtitle1'>
                {dialog.content}
              </Typography>
            </DialogContent>
          </Dialog>
        ))}
      </DialogProviderContext.Provider>
    )
  }
}

export const DialogContext = DialogProviderContext;
export const useDialogContext = () => useContext(DialogContext);
export const DialogConsumer = DialogProviderContext.Consumer;
