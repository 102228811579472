import { ServiceTypeResponse, ServiceTypesEnum } from 'app/types/services';

class ServiceType {
  id: string;
  name: string;
  code: ServiceTypesEnum;
  constructor(data: unknown) {
    if (isServiceTypeResponse(data)) {
      this.id = data.id;
      this.name = data.name;
      this.code = data.code;
    } else {
      throw new Error('Data is not ServiceTypeResponse');
    }
  }
}

const isServiceTypeResponse = (data: unknown): data is ServiceTypeResponse => {
  const d = data as ServiceTypeResponse;
  return d?.id !== undefined && d?.name !== undefined && d?.code !== undefined;
};

export default ServiceType;
