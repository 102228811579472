import React from 'react';
import styled from '@emotion/styled';
import { Paper as MuiPaper } from '@mui/material';

import { colors } from 'app/theme-colors';

const Panel = ({ ...props }) => {
  return <PaperStyled variant='outlined' {...props} />;
};

const PaperStyled = styled(MuiPaper) <{ padding: string }>`
  padding: ${({ theme, padding }) => padding || theme.spacing(2)};
  border-color: ${colors.grey.border};
`;

export default Panel;
