import { TFunction } from 'i18next';

import api from 'app/api/client';
import { OpenSnackbarType } from 'app/utils/customHooks/useSnackbar';
import { convert } from 'html-to-text';

export const RESOURCE_NOT_FOUND_RESPONSE = '404 Not Found: The requested URL was not found on the server.';
export const RESOURCE_NOT_FOUND_TEXT = 'The requested resource was not found. Please refresh the page and try again.';
const USER_HAS_NO_SUITABLE_ROLE = 'has no role for get ownership';
const VERIFICATION_CODE_MAXIMUM_ATTEMPT_IS_REACHED =
  'You have exceeded the maximal number of attempts to enter the verification code';
const INCORRECT_PASSWORD = 'Incorrect password';
const ERROR_PASSWORD_EXPIRED = {
  code: 'ERR_PASSWORD_EXPIRED',
  message: 'Your password has expired, click "Reset password" to reset it.',
};

const ERROR_MFA_BLOCKED = {
  code: 'ERR_MFA_BLOCKED',
  message: 'Login procedure restarted after 5 failed MFA code validation attempts.',
};

const ERROR_EMAIL_NOT_CONFIRMED = {
  code: 'ERR_EMAIL_NOT_CONFIRMED',
  message:
    'Your email has not been confirmed yet. Please check your inbox for the confirmation email. If this email has expired, please click the Resend confirmation button on the Portal home page.',
};

export const LOGIN_ERRORS = [ERROR_PASSWORD_EXPIRED, ERROR_MFA_BLOCKED, ERROR_EMAIL_NOT_CONFIRMED];

export interface ServerErrorInterface {
  errors: ServerErrorItemInterface[];
}

export interface ServerErrorItemInterface {
  code: string;
  detail: string;
  id: string;
  source: string;
  status: number;
  title: string;
}

interface OauthErrorResponse {
  error: string;
  error_description: string;
  error_verbose: string;
  status_code: number;
}

interface RestApiErrorResponse {
  error: string;
}

const isRestApiError = (data: unknown): data is RestApiErrorResponse => {
  const d = data as RestApiErrorResponse;
  return d?.error !== undefined;
};

export const isResponseError = (error: unknown): error is Response => {
  const e = error as Response;
  return e?.status !== undefined && e?.statusText !== undefined;
};

export const errorHandler = (error: Error | Response, openSnackbar: OpenSnackbarType, t?: TFunction): void => {
  const customError = error as Response;
  if (customError instanceof Response) {
    return serverErrorHandler(customError, openSnackbar, t);
  }

  return openSnackbar((error as Error)?.message, 'error');
};

const serverErrorHandler = (serverErrorResponse: Response, openSnackbar: OpenSnackbarType, t?: TFunction) => {
  const contentType = serverErrorResponse.headers.get('content-type');
  const contentPromise = contentType === 'application/json' ? serverErrorResponse.json() : serverErrorResponse.text();
  contentPromise.then((errorResponseBody: unknown) => {
    if (serverErrorResponse?.url?.includes('/oauth2/')) {
      return handleOauthErrors(errorResponseBody as string, openSnackbar, t);
    }
    if (serverErrorResponse?.url?.includes('/serverless/')) {
      return handleRestApiErrors(errorResponseBody, openSnackbar);
    }
    if (typeof errorResponseBody === 'string' && errorResponseBody?.includes('<html>')) {
      return openSnackbar(convert(errorResponseBody), 'error');
    }
    return handlePortalBackendErors(serverErrorResponse, errorResponseBody as ServerErrorInterface, openSnackbar, t);
  });
};

const handleOauthErrors = (errorStr: string, openSnackbar: OpenSnackbarType, t?: TFunction) => {
  try {
    const error = JSON.parse(errorStr) as OauthErrorResponse
    if (error?.error === 'invalid_client') {
      return openSnackbar(t ? t('invalidClient') : error.error_description, 'error');
    }
    openSnackbar(error?.error_description, 'error');
  } catch (err) {
    openSnackbar(errorStr, 'error');
  }
};

const handleRestApiErrors = (errorStr: string, openSnackbar: OpenSnackbarType) => {
  try {
    const error = JSON.parse(errorStr);
    if (error?.error) {
      openSnackbar(error?.error, 'error');
    }
  } catch (err) {
    openSnackbar(errorStr, 'error');
  }
};

const handlePortalBackendErors = (
  response: Response,
  error: ServerErrorInterface,
  openSnackbar: OpenSnackbarType,
  t?: TFunction,
) => {
  error?.errors?.forEach(({ source, detail, code }: ServerErrorItemInterface) => {
    if (code?.includes('ERR_CONFIRMATION_TOKEN_EXPIRED')) {
      openSnackbar(source || detail, 'error');
      setTimeout(() => api.logout(), 200);
      return;
    }

    if (detail?.includes(RESOURCE_NOT_FOUND_RESPONSE)) {
      return openSnackbar(RESOURCE_NOT_FOUND_TEXT, 'error');
    }

    if (source?.includes('Invalid code')) {
      return openSnackbar(t ? t('invalidCode') : source, 'error');
    }

    if (source?.includes('Two codes have already been sent')) {
      return openSnackbar(t ? t('twoCodesHaveBeenSent') : source, 'error');
    }

    if (source?.includes('Invalid user and password') && response?.url?.includes('/email/change')) {
      return openSnackbar(INCORRECT_PASSWORD, 'error');
    }

    if (source?.includes(USER_HAS_NO_SUITABLE_ROLE)) {
      return openSnackbar('You can transfer ownership only to a user with a member role or above', 'error');
    }

    if (
      source?.includes('invalid token') ||
      source?.includes('Auth server insufficient response') ||
      source?.includes(VERIFICATION_CODE_MAXIMUM_ATTEMPT_IS_REACHED)
    ) {
      openSnackbar(source || detail, 'error');
      setTimeout(() => api.logout(), 200);
      return;
    }

    openSnackbar(source || detail, 'error');
  });
};
