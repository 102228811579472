import React, { forwardRef } from 'react';
import styled from '@emotion/styled';
import ClearIcon from '@mui/icons-material/Clear';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import { IconButton } from '@mui/material';

import { colors } from 'app/theme-colors';

interface IProps {
  onClose: (_event: React.SyntheticEvent | React.MouseEvent, reason?: string | undefined) => void;
}

const VerificationAlert: React.FC<IProps> = forwardRef<HTMLDivElement, IProps>(function ForwardedVerificationAlert(
  { children, onClose },
  ref,
) {
  return (
    <VerificationAlertWrapper ref={ref}>
      <VerificationIcon>
        <MailOutlineIcon fontSize='inherit' />
      </VerificationIcon>
      <VerificationMessage>{children}</VerificationMessage>
      <VerificationAction>
        <IconButton size='small' color='inherit' onClick={onClose}>
          <ClearIcon fontSize='inherit' />
        </IconButton>
      </VerificationAction>
    </VerificationAlertWrapper>
  );
});

const VerificationAlertWrapper = styled.div`
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 4px;
  box-shadow: none;
  background-image: linear-gradient(${colors.primary.main}, ${colors.primary.main});
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.43;
  letter-spacing: 0.01071em;
  background-color: rgb(211, 47, 47);
  display: flex;
  padding: ${({ theme }) => theme.spacing(1, 2)};
  color: rgb(255, 255, 255);
`;

const VerificationMessage = styled.div`
  padding: ${({ theme }) => theme.spacing(1, 0)};
  font-weight: 500;
  font-size: 0.8rem;
  line-height: 1.43;
  color: #fff;
`;

const VerificationIcon = styled.div`
  margin-right: 12px;
  padding: ${({ theme }) => theme.spacing(1, 0)};
  display: flex;
  font-size: 22px;
  opacity: 0.9;
`;

const VerificationAction = styled.div`
  display: flex;
  align-items: flex-start;
  padding: ${({ theme }) => theme.spacing(0.5, 0, 0, 2)};
  margin-left: auto;
  margin-right: -8px;
`;

export default VerificationAlert;
