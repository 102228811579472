import React, { FC } from 'react';
import Head from 'next/head';

import PortalConfig from 'app/api/models/PortalConfig';

const NextHead: FC = () => {
  const getHeadTitle = () => {
    if (PortalConfig.isAliCloud()) {
      return 'SaaS 数据本地化方案';
    }
    return `${PortalConfig.title} Portal`;
  };

  return (
    <Head>
      <title>{getHeadTitle()}</title>
      <meta charSet='utf-8' />
      <meta name='description' content={PortalConfig.title} />
      <meta name='keywords' content={PortalConfig.title} />
      <meta name='author' content={PortalConfig.title} />
      <meta name='viewport' content='width=device-width, minimum-scale=1, initial-scale=1, shrink-to-fit=no' />
      <meta name='theme-color' content='#ffffff' />
    </Head>
  );
};

export default NextHead;
