import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { Box, Button, CircularProgress, Divider, Typography } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';

interface IProps {
  open: boolean;
  secondsLeft: number;
  onKeepMeSignedClick: () => void;
  onLogoutClick: () => void;
  onClose: (event?: unknown, reason?: string) => void;
}

const SessionMaintainingDialog: React.FC<IProps> = ({
  open,
  secondsLeft,
  onKeepMeSignedClick,
  onLogoutClick,
  onClose,
}) => {
  const { t } = useTranslation('SessionMaintainingDialog');
  const normalise = (value: number) => ((value - 0) * 100) / (60 - 0);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
    >
      <DialogContent>
        <Box display='flex' justifyContent='center' mb={4}>
          <CircularProgress variant='determinate' value={normalise(secondsLeft)} size={100} />
        </Box>
        <Typography variant='h4' align='center'>
          {t('sessionIsAboutToExpire')}
        </Typography>
        <Box my={2}>
          <Divider variant='middle' />
        </Box>
        <Typography variant='body1' align='center'>
          {t('youWillBeLoggedOut', { seconds: secondsLeft })}
        </Typography>
        <Typography variant='body1' align='center'>
          {t('staySignedIn')}
        </Typography>
      </DialogContent>
      <StyledDialogActions>
        <Box display='flex' flexDirection='column'>
          <Button variant='contained' color='primary' onClick={onKeepMeSignedClick} sx={{ mb: 1 }} autoFocus>
            {t('keepMeSignedIn')}
          </Button>
          <Button onClick={onLogoutClick}>{t('logout')}</Button>
        </Box>
      </StyledDialogActions>
    </Dialog>
  );
};

export default SessionMaintainingDialog;

const StyledDialogActions = styled(DialogActions)`
  justify-content: center;
`;
