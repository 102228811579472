import React from 'react';
import styled from '@emotion/styled';

import PortalConfig from 'app/api/models/PortalConfig';

const FooterDetails: React.FC = () => {
  if (PortalConfig.isDefault()) return null;

  return (
    <>
      {PortalConfig.licenses?.map((license, idx) => (
        <>
          <span>
            <a href={license.url} target='_blank' rel='noreferrer'>
              {license.img && (
                <Img
                  alt={license.img.alt}
                  src={license.img.src}
                  width={license.img.width}
                  height={license.img.height}
                />
              )}
              {license.title}
            </a>
            {idx !== PortalConfig.licenses?.length - 1 && <>&nbsp;|&nbsp;</>}
          </span>
        </>
      ))}
    </>
  );
};

export default FooterDetails;

const Img = styled.img`
  vertical-align: middle;
  margin-right: 4px;
`;
