import { ResidentFunctionResponseInterface } from 'app/types/residentFunctions';

class ResidentFunction {
  name: string;
  body?: string;
  createdAt: Date;
  updatedAt: Date;
  publishVersion: string;
  constructor(data: unknown) {
    if (isResidentFunctionResponse(data)) {
      this.name = data.script_name;
      this.body = data.script_body;
      this.createdAt = new Date(data.created_at);
      this.updatedAt = new Date(data.updated_at);
      this.publishVersion = data.publish_version;
    } else {
      throw new Error('Data is not ResidentFunctionResponse');
    }
  }
}

const isResidentFunctionResponse = (data: unknown): data is ResidentFunctionResponseInterface => {
  const d = data as ResidentFunctionResponseInterface;
  return d?.script_name !== undefined && d?.created_at !== undefined && d?.updated_at !== undefined;
};

export default ResidentFunction;
