import { FormInputs as EmailGatewayFormInputs } from 'app/forms/CreateEmailGatewayForm';
import { IEmailGatewayPayload, IEmailGatewayResponse } from 'app/types/email-gateways';

export const IMAP_PORTS = [143, 993];
export const SMTP_PORTS = [465, 587];

export const convertEmailGatewayFormDataToBackendPayload = (values: EmailGatewayFormInputs) => {

  const object: IEmailGatewayPayload = {
    title: values.emailGatewayName,
    country: values.countryCode,
    customer_code: values.tenancy,
    increment: values.increment,
  };

  if (values.inboundEmailsEnabled) {
    object['inbound'] = {
      name: values.imapServerName,
      sender_email: values.senderEmail,
      sf_service_email: values.salesforceServiceMailAddress,
      dtk: values.dtk,

      imap_host: values.imapServer,
      imap_port: values.imapPort,
      imap_username: values.imapUsername,
      imap_password: values.imapPassword,

      host: values.inboundSmtpServer,
      port: values.inboundSmtpPort,
      username: values.inboundSmtpUsername,
      password: values.inboundSmtpPassword,
    }
  }

  if (values.outboundEmailsEnabled) {
    if (values.applySmtp) {
      object['outbound'] = {
        host: values.inboundSmtpServer,
        port: values.inboundSmtpPort,
        username: values.inboundSmtpUsername,
        password: values.inboundSmtpPassword,
      }
    } else {
      object['outbound'] = {
        host: values.outboundSmtpServer,
        port: values.outboundSmtpPort,
        username: values.outboundSmtpUsername,
        password: values.outboundSmtpPassword
      }
    }
  }

  return object;
}


export const convertEmailGatewayBackendPayloadToFormData = (payload: IEmailGatewayResponse): Partial<EmailGatewayFormInputs> => {
  return {
    emailGatewayName: payload?.title,
    countryCode: `${payload?.country}:${payload?.customer_code}`,
    environmentId: payload?.environment_id,
    outboundEmailsEnabled: !!payload?.outbound,
    inboundEmailsEnabled: !!payload?.inbound,
    senderEmail: payload?.inbound?.sender_email,
    salesforceServiceMailAddress: payload?.inbound?.sf_service_email,
    applySmtp: false,

    imapServerName: payload?.inbound?.name,
    imapServer: payload?.inbound?.imap_host,
    imapPort: payload?.inbound?.imap_port,

    inboundSmtpServer: payload?.inbound?.host,
    inboundSmtpPort: payload?.inbound?.port,

    outboundSmtpServer: payload?.outbound?.host,
    outboundSmtpPort: payload?.outbound?.port?.toString() || '465',
  }
}
