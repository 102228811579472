import React from 'react';
import styled from '@emotion/styled';

import DropdownIcon from 'app/components/icons/DropdownIcon';

interface IProps {
  title?: string;
  label?: string;
}

const DropdownMenuLabel: React.FC<IProps> = ({ title = '', label }) => (
  <>
    <TitleWrapper title={title}>{label}</TitleWrapper>
    <DropdownIcon />
  </>
);

const TitleWrapper = styled.span`
  max-width: 250px;
  display: inline-block;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

export default DropdownMenuLabel;
