import AbcIcon from '@mui/icons-material/Abc';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import DataObjectIcon from '@mui/icons-material/DataObject';
import PinIcon from '@mui/icons-material/Pin';
import ToggleOnIcon from '@mui/icons-material/ToggleOn';
import DateTime from 'app/components/icons/DateTime';
import Decimal from 'app/components/icons/Decimal';
import { ISchema, ISchemaResponse, ISchemaResponseOld, SchemaCustomKeys, SchemaDataTypeEnum, SchemaFieldTypeEnum } from 'app/types/environments';
import { v4 as uuidv4 } from 'uuid';
import { ALIAS_DECIMAL_KEYS, ALIAS_RANGE_KEYS, DATE_KEYS, STRING_KEYS } from '../border';
import { FormSelectOptionInterface } from 'app/types/forms';
import { Box, MenuItem } from '@mui/material';

const getAliasKeyType = (key: string) => {
  if (STRING_KEYS.includes(key)) {
    return SchemaDataTypeEnum.string;
  }
  if (ALIAS_RANGE_KEYS.includes(key)) {
    return SchemaDataTypeEnum.integer;
  }
  if (ALIAS_DECIMAL_KEYS.includes(key)) {
    return SchemaDataTypeEnum.decimal;
  }
  return SchemaDataTypeEnum.string;
}

export const convertResponseAliasesForForm = (schema: ISchemaResponse): ISchema[] => {
  if (schema?.config_version === '2') {
    const entries = Object.entries((schema as unknown as ISchemaResponseOld).keyMapping || {});

    return entries?.map(([modelKey, modelBody]) => {
      const fields = Object.entries(modelBody.fields || {})?.map(([alias, key]) => ({ alias, key, dataType: getAliasKeyType(key), fieldType: SchemaFieldTypeEnum.indexed }));
      fields.unshift({ alias: SchemaCustomKeys.recordKey, key: SchemaCustomKeys.recordKey });
      return ({ key: modelKey, externalId: modelBody.external_id, fields: fields.sort((a, b) => a.key === SchemaCustomKeys.recordKey ? -1 : b.key === SchemaCustomKeys.recordKey ? 1 : 0), autoAssign: true })
    });
  }

  const entries = Object.entries(schema.keyMapping || {});

  return entries?.map(([modelKey, modelBody]) => {
    const fields = Object.entries(modelBody.fields || {})?.map(([alias, keyData]) => {
      if (keyData?.map_to === SchemaCustomKeys.recordKey) {
        return ({ alias, key: keyData?.map_to });
      }
      if (keyData?.field_type === SchemaFieldTypeEnum.encrypted) {
        return ({ alias, key: keyData?.map_to, dataType: keyData?.data_type, fieldType: keyData?.field_type, path: keyData?.path?.replace('$.', '') });
      }
      return ({ alias, key: keyData?.map_to, dataType: keyData?.data_type, fieldType: keyData?.field_type });
    }).sort((a, b) => a.key === SchemaCustomKeys.recordKey ? -1 : b.key === SchemaCustomKeys.recordKey ? 1 : 0);
    return ({ key: modelKey, externalId: modelBody.external_id, fields, autoAssign: true })
  })
}

export const convertResponseAliasesForBackend = (schema: ISchema[]): ISchemaResponse => {
  return ({
    keyMapping: schema?.reduce((acc, cur) => {
      const fields = cur?.fields?.reduce((acc, cur) => {
        if (cur?.key === SchemaCustomKeys.recordKey) {
          return ({ ...acc, [cur?.alias]: { map_to: cur.key } });
        }
        if (cur?.fieldType === SchemaFieldTypeEnum.encrypted) {
          return ({ ...acc, [cur?.alias]: { map_to: SchemaCustomKeys.body, data_type: cur?.dataType, field_type: cur?.fieldType, path: '$.' + cur?.path } });
        }
        return ({ ...acc, [cur?.alias]: { map_to: cur?.key, data_type: cur?.dataType, field_type: cur?.fieldType } });
      }, {})
      return ({ ...acc, [cur?.key]: ({ external_id: cur?.externalId || uuidv4(), fields }) })
    }, {})
  })
}

export const getAliasOrKey = (modelExternalId?: string, schema: ISchema[] = [], keys: string[] = []) => {
  if (modelExternalId) {
    const model = schema?.find(v => v.externalId === modelExternalId);
    if (model) {
      return keys?.map(v => {
        const field = model.fields?.find(field => field.key === v);
        return field?.alias || v;
      });
    }
  }

  return keys;
}

export const getSchemaDataTypeIcon = (dataType?: SchemaDataTypeEnum) => {
  switch (dataType) {
    case SchemaDataTypeEnum.string:
      return <AbcIcon />
    case SchemaDataTypeEnum.integer:
      return <PinIcon />;
    case SchemaDataTypeEnum.decimal:
      return <Decimal />;
    case SchemaDataTypeEnum.date:
      return <CalendarMonthIcon />;
    case SchemaDataTypeEnum.time:
      return <AccessTimeIcon />;
    case SchemaDataTypeEnum.datetime:
      return <DateTime />;
    case SchemaDataTypeEnum.boolean:
      return <ToggleOnIcon />;
    case SchemaDataTypeEnum.json:
      return <DataObjectIcon />;
    default:
      return null;
  }
}

export const renderSchemaFieldOption = (props: React.HTMLAttributes<HTMLLIElement>, option: FormSelectOptionInterface, dataType?: SchemaDataTypeEnum, disabled?: boolean) => {
  if (!dataType && DATE_KEYS.includes(option.value)) {
    dataType = SchemaDataTypeEnum.datetime;
  }
  return (
    <MenuItem disabled={disabled} {...props}>
      <Box display='grid' gridAutoFlow='column' gap={1}>
        {getSchemaDataTypeIcon(dataType)}
        {option.label}
      </Box>
    </MenuItem>
  );
}
