import React, { Children, cloneElement, useRef, useState } from 'react';
import styled from '@emotion/styled';
import { ClickAwayListener, Grow, MenuList, Paper, Popper, PopperPlacementType } from '@mui/material';

import DropdownMenuLabel from 'app/components/layout/DropdownMenuLabel';
import { colors } from 'app/theme-colors';

interface IProps {
  label?: string;
  title?: string;
  placement?: PopperPlacementType;
}

const DropdownMenuItem: React.FC<React.PropsWithChildren<IProps>> = ({
  label,
  title,
  placement,
  children,
  ...rest
}) => {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLDivElement>(null);

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen);
  };

  const handleClose = (event: MouseEvent | TouchEvent) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as Node)) {
      return;
    }

    setOpen(false);
  };

  return (
    <>
      <ClickAwayListener onClickAway={() => setOpen(false)}>
        <>
          <Wrapper onClick={handleToggle} ref={anchorRef} {...rest}>
            <DropdownMenuLabel title={title} label={label} />
          </Wrapper>
          <PopperZAligned open={open} anchorEl={anchorRef.current} transition={true} placement={placement}>
            {({ TransitionProps }) => (
              <Grow {...TransitionProps}>
                <StyledPaper>
                  <ClickAwayListener onClickAway={handleClose}>
                    <MenuListStyled>
                      {Children?.map(children, child => {
                        if (child) {
                          return cloneElement(child, { close: () => setOpen(false) })
                        }
                      })}
                    </MenuListStyled>
                  </ClickAwayListener>
                </StyledPaper>
              </Grow>
            )}
          </PopperZAligned>
        </>
      </ClickAwayListener>
    </>
  );
};

const StyledPaper = styled(Paper)`
  margin-top: -24px;
  min-width: 150px;
`;

const PopperZAligned = styled(Popper)`
  z-index: 1000;
`;

const Wrapper = styled.div`
  position: relative;
  height: 90px;
  cursor: pointer;
  display: flex;
  align-items: center;
`;

const MenuListStyled = styled(MenuList)`
  a {
    color: ${colors.text.primary};
  }
`;

export default DropdownMenuItem;
