import { CountryCode } from 'app/types/country';
import { EncryptionKeyResponse, EncryptionKeyType } from 'app/types/encryption';

class EncryptionKey {
  id: string;
  name: string;
  type: EncryptionKeyType;
  version: number;
  country: CountryCode;
  createdAt: Date;
  updatedAt: Date;
  active: boolean;
  rotationPeriod?: number;
  constructor(data: unknown) {
    if (isEncryptionKeyResponse(data)) {
      this.id = data.id;
      this.name = data.name;
      this.type = data.type;
      this.version = data.version;
      this.country = data.country;
      this.createdAt = new Date(data.created_at);
      this.updatedAt = new Date(data.updated_at);
      this.active = data.is_active;
      this.rotationPeriod = data.rotation_period;
    } else {
      throw new Error('Data is not EncryptionKeyResponse');
    }
  }
}

const isEncryptionKeyResponse = (data: unknown): data is EncryptionKeyResponse => {
  const d = data as EncryptionKeyResponse;
  return (
    d?.id !== undefined &&
    d?.name !== undefined &&
    d?.type !== undefined &&
    d?.version !== undefined &&
    d?.country !== undefined &&
    d?.created_at !== undefined &&
    d?.updated_at !== undefined &&
    d?.is_active !== undefined
  );
};

export default EncryptionKey;

export class EncryptionKeyRequestBody {
  type: EncryptionKeyType;
  name: string;
  constructor(type: EncryptionKeyType, name: string) {
    this.type = type;
    this.name = name;
  }
}

export class InCountryEncryptionKeyRequestBody extends EncryptionKeyRequestBody {
  rotation_period: number;
  constructor(type: EncryptionKeyType, name: string, rotationPeriod: number) {
    super(type, name);
    this.rotation_period = rotationPeriod;
  }
}

export class AwsEncryptionKeyRequestBody extends EncryptionKeyRequestBody {
  key_data: {
    encrypted_key: string;
    master_key_id: string;
    region: string;
    iam: {
      key_id: string;
      secret_key: string;
    };
  };
  constructor(
    type: EncryptionKeyType,
    name: string,
    region: string,
    encryptedKey: string,
    masterKeyId: string,
    iamAccessKeyId: string,
    iamSecretKey: string,
  ) {
    super(type, name);
    this.key_data = {
      region,
      encrypted_key: encryptedKey,
      master_key_id: masterKeyId,
      iam: {
        key_id: iamAccessKeyId,
        secret_key: iamSecretKey,
      },
    };
  }
}
