import { css } from '@emotion/react';
import { Router } from 'next/router';
import NProgress from 'nprogress';

import { colors } from 'app/theme-colors';

import 'nprogress/nprogress.css';

export const configureNprogress = (): void => {
  NProgress.configure({ showSpinner: false });
  Router.events.on('routeChangeStart', () => NProgress.start());
  Router.events.on('routeChangeComplete', () => NProgress.done());
};

export const nProgressCSS = css`
  #nprogress .bar {
    background-color: ${colors.primary.main} !important;
    height: 4px !important;
  }

  #nprogress .spinner-icon {
    border-top-color: ${colors.primary.main} !important;
    border-left-color: ${colors.primary.main} !important;
  }

  #nprogress .peg {
    box-shadow: 0 0 10px ${colors.primary.main}, 0 0 5px ${colors.primary.main} !important;
  }

  .peg {
    transform: rotate(0deg) translate(0px, -0px) !important;
  }
`;
