import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import React from 'react';
import getConfig from 'next/config';
import { NextGetConfig } from 'app/types/nextjs';
import { useRouter } from 'next/router';
import { Routes } from '../constants';

const { publicRuntimeConfig: { RECAPTCHA_VERSION, IC_WD_RECAPTCHA_SITE_KEY } } = getConfig() as NextGetConfig;

const GoogleCaptchaWrapper = ({ children }: { children: React.ReactNode }) => {
  const router = useRouter();

  const enableCaptchaV3 = RECAPTCHA_VERSION === '3' && (router.pathname.includes(Routes.invite.resend().pathname) || router.pathname.includes(Routes.member.invite().pathname) || router.pathname.includes(Routes.profile.changeEmail().pathname));

  if (enableCaptchaV3) {
    return (
      <GoogleReCaptchaProvider
        reCaptchaKey={IC_WD_RECAPTCHA_SITE_KEY ?? 'NOT DEFINED'}
        useRecaptchaNet={true}
        scriptProps={{
          async: false,
          defer: false,
          appendTo: 'head',
          nonce: undefined,
        }}
      >
        {children}
      </GoogleReCaptchaProvider>
    );
  }

  return <>{children}</>;
}

export default GoogleCaptchaWrapper;
