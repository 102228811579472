import React, { ReactElement, useRef, useState } from 'react';
import { IIdleTimer, withIdleTimer } from 'react-idle-timer';

import api from 'app/api/client';
import SessionMaintainingDialog from 'app/components/common/SessionMaintainingDialog';
import {
  ACCESS_TOKEN_REFRESH_THRESHOLD_SECONDS,
  ACCESS_TOKEN_TTL_CHECK_INTERVAL,
  IDLE_TIMEOUT,
} from 'app/utils/constants/userActivityMonitor';
import { useSnackbarContext } from 'app/utils/context/SnackbarProvider';
import { cookiesService } from 'app/utils/cookies';
import { useInterval } from 'app/utils/customHooks/useInterval';
import { errorHandler } from 'app/utils/errors';

interface IIdleTimerComponent extends IIdleTimer {
  children: ReactElement;
}

const IdleTimerComponent: React.FC<IIdleTimerComponent> = ({ children }) => {
  return children;
};

const IdleTimer = withIdleTimer(IdleTimerComponent);

interface IProps {
  children: ReactElement;
}

const UserActivityComponent: React.FC<IProps> = ({ children }) => {
  const { openSnackbar } = useSnackbarContext();
  const ref = useRef<IIdleTimer | null>(null);
  const [idle, setIdle] = useState(false);
  const [refreshInProgress, setRefreshInProgress] = useState(false);
  const [dialogOpened, setDialogOpened] = useState(false);
  const [secondsLeft, setSecondsLeft] = useState<number | null>(null);

  useInterval(() => {
    if (!cookiesService.hasExpCookie(document.cookie)) return api.logout();

    const ttl = cookiesService.getAccessTll(document.cookie);

    if (ttl < 0) {
      return api.logout();
    }

    if ((ttl <= ACCESS_TOKEN_REFRESH_THRESHOLD_SECONDS && !refreshInProgress && idle) || dialogOpened) {
      setSecondsLeft(ttl);
      if (dialogOpened) return;
      handleDialogOpen();
    } else if (ttl <= ACCESS_TOKEN_REFRESH_THRESHOLD_SECONDS && !refreshInProgress && !idle && !dialogOpened) {
      extendSession();
    }
  }, ACCESS_TOKEN_TTL_CHECK_INTERVAL);

  const onActive = () => {
    if (!ref.current) {
      return;
    }
    setIdle(false);
  };

  const onIdle = () => {
    if (!ref.current) {
      return;
    }
    setIdle(true);
  };

  const handleDialogOpen = () => {
    setDialogOpened(true);
  };

  const onDialogCloseClick = (_?: unknown, reason?: string) => {
    if (reason && reason == 'backdropClick') return;
  };

  const onLogoutClick = () => {
    setDialogOpened(false);
    api.logout();
  };

  const extendSession = async () => {
    try {
      setRefreshInProgress(true);
      await api?.fetchConfirmationStatus();
      setRefreshInProgress(false);
    } catch (err: unknown) {
      errorHandler(err as Error, openSnackbar);
    }
  };

  const onKeepMeSignedClick = async () => {
    await extendSession();
    setRefreshInProgress(false);
    setIdle(false);
    setDialogOpened(false);
  };

  return (
    <>
      {!!secondsLeft && (
        <SessionMaintainingDialog
          open={dialogOpened}
          secondsLeft={secondsLeft}
          onKeepMeSignedClick={onKeepMeSignedClick}
          onLogoutClick={onLogoutClick}
          onClose={onDialogCloseClick}
        />
      )}
      <IdleTimer ref={ref} onActive={onActive} onIdle={onIdle} timeout={IDLE_TIMEOUT}>
        {children}
      </IdleTimer>
    </>
  );
};

export default UserActivityComponent;
