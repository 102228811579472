import { SvgIcon, SvgIconTypeMap } from '@mui/material';
import { OverridableComponent } from '@mui/material/OverridableComponent';

const DateTime: OverridableComponent<SvgIconTypeMap> = () => {
  return (
    <SvgIcon viewBox="0 0 24 24">
      <path d="M19 4H18V2H16V4H8V2H6V4H5C3.89 4 3.01 4.9 3.01 6L3 20C3 21.1 3.89 22 5 22H19C20.1 22 21 21.1 21 20V6C21 4.9 20.1 4 19 4ZM19 20H5V10H19V20ZM9 14H7V12H9V14ZM13 14H11V12H13V14ZM17 14H15V12H17V14ZM9 18H7V16H9V18ZM13 18H11V16H13V18ZM17 18H15V16H17V18Z" />
      <circle cx="16.6667" cy="16.6667" r="6" fill="white" />
      <path d="M16.66 10C12.98 10 10 12.9867 10 16.6667C10 20.3467 12.98 23.3333 16.66 23.3333C20.3467 23.3333 23.3333 20.3467 23.3333 16.6667C23.3333 12.9867 20.3467 10 16.66 10ZM16.6667 22C13.72 22 11.3333 19.6133 11.3333 16.6667C11.3333 13.72 13.72 11.3333 16.6667 11.3333C19.6133 11.3333 22 13.72 22 16.6667C22 19.6133 19.6133 22 16.6667 22Z" />
      <path d="M17 13.3333H16V17.3333L19.5 19.4333L20 18.6133L17 16.8333V13.3333Z" fill="black" />
    </SvgIcon>
  );
};

export default DateTime;
