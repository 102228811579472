import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';

import PortalConfig from 'app/api/models/PortalConfig';

const FooterMenu: React.FC = ({ ...rest }) => {
  const { t } = useTranslation('FooterMenu');

  const renderSupportItem = () => {
    if (PortalConfig.isDCC()) {
      return null;
    }
    if (PortalConfig.supportTicket !== '') {
      return (
        <FooterLink target='_blank' href={PortalConfig.supportTicket}>
          {t('contactSupport')}
        </FooterLink>
      );
    }
    return (
      <FooterLink target='_blank' href={`mailto:${PortalConfig.supportEmail || ''}`}>
        {t('contactSupport')}
      </FooterLink>
    );
  };

  return (
    <Wrapper {...rest}>
      <FooterLink target='_blank' href={PortalConfig.website}>
        {t('incountryAddress')}
      </FooterLink>
      {PortalConfig.isDCC() && (
        <FooterLink target='_blank' href='http://dataresidency.cn/'>
          dataresidency.cn
        </FooterLink>
      )}
      {renderSupportItem()}
      {PortalConfig.supportTel !== '' && (
        <FooterLink target='_blank' href={`tel:${PortalConfig.supportTel}`}>
          {t('phoneSupport', { phone: PortalConfig.supportTel })}
        </FooterLink>
      )}
      <FooterLink target='_blank' href={PortalConfig.urlToDocs}>
        {t('documentation')}
      </FooterLink>
      {PortalConfig.urlPrivacyPolicy !== '' && (
        <FooterLink target='_blank' href={PortalConfig.urlPrivacyPolicy}>
          {t('privacyPolicy')}
        </FooterLink>
      )}
      {PortalConfig.urlTos && (
        <FooterLink target='_blank' href={PortalConfig.urlTos}>
          {t('terms')}
        </FooterLink>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  align-self: center;
  gap: 32px;
`;

const FooterLink = styled.a`
  text-decoration: none;
  white-space: nowrap;
`;

export default FooterMenu;
