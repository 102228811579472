import { Redirect } from 'next';
import { ParsedUrlQuery } from 'querystring';

export const parseQueryParam = (qParam: string | string[] | undefined): string | undefined => {
  if (typeof qParam === 'string') {
    return qParam;
  }
};

export const getPathnameFromNextContext = (url: string, query: ParsedUrlQuery): string => {
  const pathQueries = Object.entries(query || {});
  const queries = url?.split('?')?.[1]?.split('&')?.map(v => v?.split('=')?.[0]);

  return pathQueries?.filter(v => !queries?.includes(v?.[0]))?.reduce((acc, [key, val]) => acc?.replace(new RegExp('\\b' + val + '\\b'), `[${key}]`), decodeURIComponent(url?.split('?')?.[0]));
};

export const getNextRedirect = (destination: string, permanent?: boolean): { redirect: Redirect } => ({
  redirect: {
    destination,
    permanent: !!permanent,
  },
});
