import { createTheme } from '@mui/material';
import { colors } from 'app/theme-colors';

export const theme = createTheme({
  typography: {
    fontFamily: 'CircularBook, Arial, Helvetica, Tahoma',
    h1: { fontSize: 36 },
    h2: { fontSize: 32 },
    h3: { fontSize: 24 },
    h4: { fontSize: 20 },
    h5: { fontSize: 18 },
    h6: { fontSize: 16 },
    body1: { fontSize: 14 },
    body2: { fontSize: 13 },
  },
  palette: {
    primary: { main: colors.primary.main, dark: colors.primary.dark, contrastText: '#ffffff' },
    secondary: { main: colors.secondary.main, dark: colors.secondary.dark, contrastText: '#ffffff' },
    text: {
      primary: colors.text.primary,
    },
    background: {
      default: colors.alternative.dark,
      paper: colors.white,
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          fontFamily: 'CircularBold'
        }
      }
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: '12px',
          backgroundColor: 'rgb(36, 41, 47)',
        },
        arrow: {
          color: 'rgb(36, 41, 47)',
        },
      }
    }
  },
});
