import { parseISO } from 'date-fns';

import { Roles } from 'app/types/auth';
import { MemberResponseInterface } from 'app/types/members';

class Member {
  id: string;
  email: string;
  role: Roles;
  joinedAt: Date;
  constructor(data: unknown) {
    if (isMemberResponse(data)) {
      this.id = data.id;
      this.email = data.email;
      this.role = data.role;
      this.joinedAt = parseISO(data.joined);
    } else {
      throw new Error('Data is not MemberResponse');
    }
  }

  public isOwnerRole = (): boolean => this.role === Roles.owner;
}

const isMemberResponse = (data: unknown): data is MemberResponseInterface => {
  const d = data as MemberResponseInterface;
  return d?.id !== undefined && d?.email !== undefined && d?.role !== undefined && d?.joined !== undefined;
};

export default Member;
