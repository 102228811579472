import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import React from 'react';

import { CurrentUser } from 'app/api/models/CurrentUser';
import { DialogProvider } from 'app/utils/context/DialogProvider';
import SnackbarProvider from 'app/utils/context/SnackbarProvider';
import GoogleCaptchaWrapper from './RecaptchaProvider';
import { UsageProvider } from './UsageProvider';

interface IProps {
  user?: CurrentUser;
  children: JSX.Element[];
}

const Providers: React.FC<IProps> = ({ user, children }) => (
  <UsageProvider user={user}>
    <DialogProvider>
      <SnackbarProvider>
        <GoogleCaptchaWrapper>
          <LocalizationProvider dateAdapter={AdapterDateFns}>{children}</LocalizationProvider>
        </GoogleCaptchaWrapper>
      </SnackbarProvider>
    </DialogProvider>
  </UsageProvider>
);

export default Providers;
