import { ResidentFunctionResponseInterface, ResidentFunctionsResponseMetaInterface } from 'app/types/residentFunctions';

class ResidentFunctionMeta {
  count: number;
  limit: number;
  offset: number;
  total: number;
  expiredAt: Date;
  constructor(data: unknown) {
    if (isResidentFunctionMetaResponse(data)) {
      this.count = data?.count;
      this.limit = data?.limit;
      this.offset = data?.offset;
      this.total = data?.total;
      this.expiredAt = data?.expired_at ? new Date(data?.expired_at) : new Date();
    } else {
      throw new Error('Data is not isResidentFunctionMetaResponse');
    }
  }
}

const isResidentFunctionMetaResponse = (data: unknown): data is ResidentFunctionsResponseMetaInterface => {
  return true;
};

export default ResidentFunctionMeta;
