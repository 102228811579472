import { parseISO } from 'date-fns';

import { InviteResponseInterface } from 'app/types/members';

class Invite {
  id: string;
  email: string;
  status: string;
  token: string;
  joinedAt: Date;
  constructor(data: unknown) {
    if (isInviteResponse(data)) {
      this.id = data.id;
      this.email = data.email;
      this.token = data.invite_token;
      this.status = data.status;
      this.joinedAt = parseISO(data.joined);
    } else {
      throw new Error('Data is not InviteResponse');
    }
  }
}

const isInviteResponse = (data: unknown): data is InviteResponseInterface => {
  const d = data as InviteResponseInterface;
  return (
    d?.id !== undefined &&
    d?.email !== undefined &&
    d?.status !== undefined &&
    d?.joined !== undefined &&
    d?.invite_token !== undefined
  );
};

export default Invite;
